import { Routes } from '@angular/router';
import { AuthGuard } from './core/auth.guard';

import { HomeComponent } from './components/home/home.component';
import { OrdersComponent } from './components/orders/orders.component';
import { PayableOrderComponent } from './components/payable-order/payable-order.component';
import { WarrantyOrderComponent } from './components/warranty-order/warranty-order.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { CustomersComponent } from './components/customers/customers.component';
import { DevicesComponent } from './components/devices/devices.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { InformationsComponent } from './components/informations/informations.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { ErrorComponent } from './components/error/error.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] },
  { path: 'orders-payable', component: PayableOrderComponent, canActivate: [AuthGuard] },
  { path: 'orders-warranty', component: WarrantyOrderComponent, canActivate: [AuthGuard] },
  { path: 'archive', component: ArchiveComponent, canActivate: [AuthGuard] },
  { path: 'customers', component: CustomersComponent, canActivate: [AuthGuard] },
  { path: 'devices', component: DevicesComponent, canActivate: [AuthGuard] },
  { path: 'user-settings', component: UserSettingsComponent, canActivate: [AuthGuard] },
  { path: 'informations', component: InformationsComponent, canActivate: [AuthGuard] },
  { path: 'stats', component: StatisticsComponent, canActivate: [AuthGuard] },
  { path: '**', component: ErrorComponent, canActivate: [AuthGuard] },
];
