import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DataService } from '../../services/data-service/data.service';
import { PagesService } from '../../services/pages-service/pages.service';
import { CustomersService } from '../../services/customers-service/customers.service';
import { CustomerComponent } from '../customer/customer.component';
import { CustomerData } from '../../models/customer.model';
import { columnNames } from './customers.data';
import { Observable } from 'rxjs';
import { map, take, takeWhile } from 'rxjs/operators';
import { CustomerSearchComponent } from '../customer-search/customer-search.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, OnDestroy {

  public loading: boolean;

  public initialLoading: boolean;

  public resultsLength: number;

  /**
   * Data stream of customers from firestore collection provided by `data.service.ts`
   * @type {Observable<Array<CustomerData>>}
   * @memberof CustomersComponent
   */
  public dataSource$: Observable<Array<CustomerData>>;

  /**
   * Form control made to filter dataSource$
   * @type {FormControl}
   * @memberof CustomersComponent
   */
  public filterFC: FormControl = new FormControl('', [Validators.required]);

  /**
   * array of column names for mat-table , array was created inside `customers.data.ts` file
   * @type {Array<string>}
   * @memberof CustomersComponent
   */
  public columns: Array<string> = columnNames;

  constructor(private _dataService: DataService, private _pagesService: PagesService, public customersService: CustomersService,
    private _matDialog: MatDialog) { }

  /**
   * Method that filters `dataSource$` based on filterFC Form Control
   * @memberof CustomersComponent
   */
  public applyFilter() {
    this.customersService.customerDialogShortVersion = false;
    this.customersService.customerSearchString = this.filterFC.value;
    this._matDialog.open(CustomerSearchComponent, {
      maxHeight: 700,
      maxWidth: 500
    });
  }

  public clearForm() {
    this.filterFC.reset();
  }

  /**
   * deletes customer from firestore collection by invoking method from `customers.service.ts`
   * @param {string} customerID
   * @memberof CustomersComponent
   */
  public delete(customerID: string): void {
    this.customersService.deleteCustomer(customerID);
  }

  /**
   *  Opens mat dialog from which you can add new customer or edit current and see client's orders
   *  then if client has orders you can open mat dialog with current order
   * @param {string} [customerID] OPTIONAL customer ID
   * @param {CustomerData} [customer] OPTIONAL all data about customer that is saved in firestore
   * @memberof CustomersComponent
   */
  public openDialog(customerID?: string, customer?: CustomerData): void {
    this._pagesService.openDialog(CustomerComponent, customerID, customer);
  }

  ngOnInit(): void {
    // this.initialLoading = true;
    // this.dataSource$ = this._dataService.customers$.pipe(
    //   map(data => {
    //     this.initialLoading = false;
    //     return data;
    //   })
    // );
    this._pagesService.selectedCustomer = true;
  }

  ngOnDestroy(): void {
    this._pagesService.selectedCustomer = false;
  }
}
