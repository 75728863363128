import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { DeviceData } from '../../models/device.model';
import { Observable } from 'rxjs';
import { SweetAlertResult } from 'sweetalert2';
import swal from 'sweetalert2';
import * as _ from 'lodash';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  public loading: boolean;
  private _devicesCollection: AngularFirestoreCollection<DeviceData>;
  public selectedDeviceID: string;
  public selectedDevice = {
    deviceID: <string>'',
    deviceCatalogNumber: <string>'',
    deviceType: <string>'',
    deviceEquipment: <Array<string>>[]
  };
  public editingDevice: boolean;

  public deviceFormFields = {
    deviceID: <AbstractControl> undefined,
    deviceType: <AbstractControl> undefined,
    deviceCatalogNumber: <AbstractControl> undefined,
    deviceEquipment: <AbstractControl> undefined,
  };

  public deviceSearchTerms: string;
  public deviceSearchString: string;

  public deviceDialogShortVersion: boolean;

  constructor(private _afs: AngularFirestore, private _snackBar: MatSnackBar, private _matDialog: MatDialog) {
    this._devicesCollection = this._afs.collection('devices');
    this.deviceSearchTerms = 'deviceType';
  }

  public addDevice(device: DeviceData, addFromOrder?: boolean, orderID?: string) {
    this.loading = true;
    let equipment: Array<any>;
    if (addFromOrder !== true) {
      equipment = this._filterEquipment(device.deviceEquipment);
    } else {
      equipment = device.deviceEquipment.toString().split(',');
      for (let i = 0; i < equipment.length; i++) {
        equipment[i] = { equipmentName: equipment[i].trim() };
      }
      equipment = this._filterEquipment(equipment);
    }
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    this._devicesCollection.doc(autoId).set({
      deviceEquipment: equipment,
      deviceCatalogNumber: device.deviceCatalogNumber,
      deviceID: autoId,
      deviceType: device.deviceType
    })
      .then(() => {
        this._showFeedbackToUser('Urządzenie zostało dodane ✔');
        if (addFromOrder === true) {
          this._updateOrder(autoId, device.deviceType, device.deviceCatalogNumber, orderID, equipment);
        }
        setTimeout(() => {
          this._snackBar.dismiss();
        }, 2000);
      })
      .catch(err => {
        this._handleError(err);
      });
  }

  public deleteDevice(deviceID: string): void {
    swal({
      title: 'Czy jesteś pewnien?',
      text: 'Ta operacja jest nieodwracalna!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Usuń!',
      cancelButtonText: 'Anuluj'
    }).then((result) => {
      if (result.value) {
        this._devicesCollection.doc(deviceID).delete()
          .then(() => {
            this._showFeedbackToUser('Urządzenie zostało usunięte ❌', true);
          })
          .catch(err => {
            this._handleError(err);
          });
      }
    });
  }

  public editDevice(device: DeviceData) {
    this.loading = true;
    let equipment: Array<string> = [];
    for (let i = 0; i < device.deviceEquipment.length; i++) {
      if (equipment[i] === undefined) {
        equipment[i] = device.deviceEquipment[i].equipmentName;
      }
    }
    equipment = _.compact(equipment);
    equipment = _.uniq(equipment);
    this._devicesCollection.doc(this.selectedDeviceID).update({
      deviceEquipment: equipment,
      deviceCatalogNumber: device.deviceCatalogNumber,
      deviceType: device.deviceType
    })
      .then(() => {
        this._showFeedbackToUser('Zaktualizowano urządzenie ✔');
      })
      .catch(err => {
        this.loading = false;
        this._handleError(err);
      });
  }

  public selectDevice(device: DeviceData): void {
    this.editingDevice = true;
    this.selectedDeviceID = device.deviceID;
    const equipment = this._filterEquipment(device.deviceEquipment);
    this.selectedDevice = {
      deviceID: device.deviceID,
      deviceEquipment: equipment,
      deviceCatalogNumber: device.deviceCatalogNumber,
      deviceType: device.deviceType
    };
  }

  public deselectDevice(): void {
    this.editingDevice = false;
    this.selectedDeviceID = undefined;
    this.selectedDevice = {
      deviceID: '',
      deviceCatalogNumber: '',
      deviceType: '',
      deviceEquipment: []
    };
  }

  public selectDeviceForSearch(device: DeviceData) {
    this.selectedDeviceID = device.deviceID;
    this.selectedDevice = {
      deviceID: device.deviceID,
      deviceType: device.deviceType,
      deviceCatalogNumber: device.deviceCatalogNumber,
      deviceEquipment: device.deviceEquipment
    };
    this.setDevice();
  }

  public setDevice() {
    this.deviceFormFields.deviceID.setValue(this.selectedDeviceID);
    this.deviceFormFields.deviceType.setValue(this.selectedDevice.deviceType);
    this.deviceFormFields.deviceCatalogNumber.setValue(this.selectedDevice.deviceCatalogNumber);
    this.deviceFormFields.deviceEquipment.setValue(this.selectedDevice.deviceEquipment);
    this._matDialog.closeAll();
  }

  public checkIfExists(device: DeviceData, orderID: string) {
    let deviceCollection$: Observable<any>;
    let numberOfRepeats: number;
    numberOfRepeats = 0;
    deviceCollection$ = this._afs.collection('devices', ref => {
      return ref.where('deviceCatalogNumber', '==', device.deviceCatalogNumber)
        .where('deviceType', '==', device.deviceType);
    }).valueChanges();

    deviceCollection$.subscribe(data => {
      while (numberOfRepeats === 0) {
        if (data.length === 0) {
          this.addDevice(device, true, orderID);
        } else {
          this._updateOrder(data[0].deviceID, device.deviceType, device.deviceCatalogNumber, orderID, device.deviceEquipment);
        }
        numberOfRepeats++;
      }
    });
  }

  private _updateOrder(deviceID: string, deviceName: string, deviceCatalogNumber: string, orderID: string, equipment: any) {
    let equipmentFiltered: Array<any> = equipment.toString().split(',');
    for (let i = 0; i < equipmentFiltered.length; i++) {
      equipmentFiltered[i] = { equipmentName: equipmentFiltered[i].trim() };
    }
    equipmentFiltered = this._filterEquipment(equipmentFiltered);
    this._afs.collection('orders').doc(orderID).update({
      device: {
        deviceID: deviceID,
        deviceType: deviceName,
        deviceCatalogNumber: deviceCatalogNumber
      },
      deviceEquipment: equipmentFiltered
    })
      .catch(err => {
        this._handleError(err);
      });
  }

  /**
   *
   * @private
   * @param {string} message
   * @param {boolean} [deleteOperation]
   * @memberof DevicesService
   */
  private _showFeedbackToUser(message: string, deleteOperation?: boolean): void {
    if (deleteOperation !== true) {
      this.loading = false;
      this._matDialog.closeAll();
    }
    this._snackBar.open(message);
    setTimeout(() => {
      this._snackBar.dismiss();
    }, 2000);
  }

  private _filterEquipment(equipmentUnfiltered: Array<any>): Array<string> {
    let equipment: Array<string> = [];
    if (equipmentUnfiltered[0].equipmentName !== undefined) {
      for (let i = 0; i < equipmentUnfiltered.length; i++) {
        if (equipment[i] === undefined) {
          equipment[i] = equipmentUnfiltered[i].equipmentName;
        }
      }
    } else {
      equipment = equipmentUnfiltered;
    }
    equipment = _.compact(equipment);
    equipment = _.uniq(equipment);
    return equipment;
  }

  /**
   * Returns error with swal
   * @private
   * @param {*} err incoming error
   * @returns {Promise<SweetAlertResult>} swal modal popup
   * @memberof DevicesService
   */
  private _handleError(err): Promise<SweetAlertResult> {
    return swal({
      type: 'error',
      title: err.code,
      text: err.message
    });
  }
}
