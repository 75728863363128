import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AuthService } from './services/auth-service/auth.service';
import { UserDataService } from './services/user-data/user-data.service';
import { MediaService } from './services/media-service/media.service';
import { fadeAnimation } from './animations/fade.animation';
import { PagesService } from './services/pages-service/pages.service';
import { InformationsComponent } from './components/informations/informations.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit {

  public opened: boolean;
  public arrowClicked: boolean;
  public userDocument: any;
  public day: string;
  public month: string;
  public year: number;
  public hour: string;
  public minutes: string;

  constructor(private _matDialog: MatDialog, public authService: AuthService, public userDataService: UserDataService, public mediaService: MediaService,
    public pagesService: PagesService) {
    this.opened = true;
  }

  public getRouterOutletState(outlet: RouterOutlet): any {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public arrowToggle(): void {
    this.arrowClicked = !this.arrowClicked;
  }

  public openCredits() {
    this._matDialog.open(InformationsComponent);
  }

  private _getDate(): void {
    this.day = new Date().getDate().toString();
    this.month = (new Date().getMonth() + 1).toString();
    this.year = new Date().getFullYear();
    this.hour = new Date().getHours().toString();
    this.minutes = new Date().getMinutes().toString();
    if (this.day.length === 1) {
      this.day = `0${this.day}`;
    }
    if (this.month.length === 1) {
      this.month = `0${this.month}`;
    }
    if (this.minutes.length === 1) {
      this.minutes = `0${this.minutes}`;
    }
    if (this.hour.length === 1) {
      this.hour = `0${this.hour}`;
    }
    setInterval(() => {
      this.day = new Date().getDate().toString();
      this.month = (new Date().getMonth() + 1).toString();
      this.year = new Date().getFullYear();
      this.hour = new Date().getHours().toString();
      this.minutes = new Date().getMinutes().toString();
      if (this.day.length === 1) {
        this.day = `0${this.day}`;
      }
      if (this.month.length === 1) {
        this.month = `0${this.month}`;
      }
      if (this.minutes.length === 1) {
        this.minutes = `0${this.minutes}`;
      }
      if (this.hour.length === 1) {
        this.hour = `0${this.hour}`;
      }
    }, 1000);
  }

  ngOnInit() {
    this.arrowClicked = false;
    this._getDate();
  }
}
