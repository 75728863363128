import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DevicesService } from 'src/app/services/devices-service/devices.service';
import { PagesService } from 'src/app/services/pages-service/pages.service';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { DeviceData } from 'src/app/models/device.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceComponent } from '../device/device.component';


@Component({
  selector: 'app-device-search',
  templateUrl: './device-search.component.html',
  styleUrls: ['./device-search.component.scss']
})
export class DeviceSearchComponent implements OnInit {

  public loading: boolean;
  public length: number;
  private _devicesCollection: AngularFirestoreCollection<DeviceData>;
  public devices$: Observable<Array<DeviceData>>;

  constructor(public devicesService: DevicesService, public pagesService: PagesService, private _afs: AngularFirestore,
    private _matDialog: MatDialog) { }

  public openDeviceDialog(device: DeviceData) {
    this._matDialog.closeAll();
    this.devicesService.selectDevice(device);
    this._matDialog.open(DeviceComponent, {
      maxHeight: 700
    });
  }

  ngOnInit() {
    this.loading = true;
    switch (this.devicesService.deviceSearchTerms) {
      case 'deviceType':
        this._devicesCollection = this._afs.collection('devices', ref => {
          return ref.where('deviceType', '>=', this.devicesService.deviceSearchString).where('deviceType', '<=', `${this.devicesService.deviceSearchString}z`);
        });
        break;
      case 'deviceCatalogNumber':
        this._devicesCollection = this._afs.collection('devices', ref => {
          return ref.where('deviceCatalogNumber', '>=', this.devicesService.deviceSearchString).
            where('deviceCatalogNumber', '<=', `${this.devicesService.deviceSearchString}z`);
        });
        break;
      case 'deviceEquipment':
        this._devicesCollection = this._afs.collection('devices', ref => {
          return ref.where('deviceEquipment', 'array-contains', this.devicesService.deviceSearchString);
        });
        break;
      default:
        this._devicesCollection = this._afs.collection('devices', ref => {
          return ref.where('deviceType', '>=', this.devicesService.deviceSearchString).where('deviceType', '<=', `${this.devicesService.deviceSearchString}z`);
        });
        break;
    }
    this.devices$ = this._devicesCollection.valueChanges().pipe(
      map(data => {
        this.loading = false;
        this.length = data.length;
        return data;
      })
    );
  }
}

