import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';

import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { AuthGuard } from './core/auth.guard';
import { MaterialModule } from './material/material.module';
import { NgDragDropModule } from 'ng-drag-drop';
import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderComponent } from './components/order/order.component';
import { PayableOrderComponent } from './components/payable-order/payable-order.component';
import { WarrantyOrderComponent } from './components/warranty-order/warranty-order.component';
import { CustomersComponent } from './components/customers/customers.component';
import { CustomerComponent } from './components/customer/customer.component';
import { DevicesComponent } from './components/devices/devices.component';
import { DeviceComponent } from './components/device/device.component';
import { InformationsComponent } from './components/informations/informations.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { ErrorComponent } from './components/error/error.component';
import { PrintComponent } from './components/print/print.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { ScrollableDirective } from './directives/scrollable-directive/scrollable.directive';
import { CalendarDayComponent } from './components/calendar-day/calendar-day.component';
import { CustomerSearchComponent } from './components/customer-search/customer-search.component';
import { CustomerOrderSearchComponent } from './components/customer-order-search/customer-order-search.component';
import { DeviceSearchComponent } from './components/device-search/device-search.component';
import { DeviceOrderSearchComponent } from './components/device-order-search/device-order-search.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    OrdersComponent,
    PayableOrderComponent,
    WarrantyOrderComponent,
    CustomerComponent,
    CustomersComponent,
    DeviceComponent,
    DevicesComponent,
    InformationsComponent,
    UserSettingsComponent,
    OrderComponent,
    ErrorComponent,
    PrintComponent,
    StatisticsComponent,
    CalendarComponent,
    ArchiveComponent,
    ScrollableDirective,
    CalendarDayComponent,
    CustomerSearchComponent,
    CustomerOrderSearchComponent,
    DeviceSearchComponent,
    DeviceOrderSearchComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    MaterialModule,
    NgDragDropModule.forRoot(),
    ChartsModule
  ],
  entryComponents: [OrderComponent, CustomerComponent, DeviceComponent, PrintComponent, CalendarDayComponent, CustomerSearchComponent,
    CustomerOrderSearchComponent, DeviceSearchComponent, DeviceOrderSearchComponent],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
