import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService} from '../services/auth-service/auth.service';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

      return this.authService.user.pipe(
           take(1),
           map(user => !!user),
           tap(loggedIn => {
             if (!loggedIn) {
              swal({
                type: 'error',
                title: 'Dostęp wzbroniony!',
                text: 'Zaloguj się aby zyskać dostęp!',
                confirmButtonColor: '#ffeb3b',
              });
             }
         })
    );
  }
}
