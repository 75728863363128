import { Injectable } from '@angular/core';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  private _mediaQuery$: Subscription;
  private _activeMediaQuery: string;
  public bigScreen: boolean;
  public smallScreen: boolean;

  constructor(private _observableMedia: ObservableMedia) {
    this._mediaQuery$ = this._observableMedia.subscribe((change: MediaChange) => {
      this._activeMediaQuery = `${change.mqAlias}`;
      if (this._activeMediaQuery === 'xs' || this._activeMediaQuery === 'sm') {
        this.smallScreen = true;
        this.bigScreen = false;
      } else {
        this.smallScreen = false;
        this.bigScreen = true;
      }
    });
   }
}
