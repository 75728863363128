import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CustomersService } from '../../services/customers-service/customers.service';
import { CustomerComponent } from '../customer/customer.component';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { CustomerData } from '../../models/customer.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagesService } from '../../services/pages-service/pages.service';

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit {

  public loading: boolean;
  public length: number;
  private _customersCollection: AngularFirestoreCollection<CustomerData>;
  public customers$: Observable<Array<CustomerData>>;

  constructor(public customersService: CustomersService, public pagesService: PagesService , private _afs: AngularFirestore, private _matDialog: MatDialog) { }


  public openCustomerDialog(customer: CustomerData) {
    this._matDialog.closeAll();
    this.customersService.selectCustomer(customer);
    this._matDialog.open(CustomerComponent, {
      maxHeight: 700,
      minWidth: 800,
    });
  }

  ngOnInit() {
    this.loading = true;
    switch (this.customersService.customerSearchTerms) {
      case 'customerName':
        this._customersCollection = this._afs.collection('companies', ref => {
          return ref.where('customerName', '>=' , this.customersService.customerSearchString).
          where('customerName', '<=', `${this.customersService.customerSearchString}z`);
        });
        break;
      case 'customerStreet':
        this._customersCollection = this._afs.collection('companies', ref => {
          return ref.where('customerStreet', '==' , this.customersService.customerSearchString);
        });
        break;
      case 'customerCity':
        this._customersCollection = this._afs.collection('companies', ref => {
          return ref.where('customerCity', '==' , this.customersService.customerSearchString);
        });
        break;
      case 'customerPostalCode':
        this._customersCollection = this._afs.collection('companies', ref => {
          return ref.where('customerPostalCode', '==' , this.customersService.customerSearchString);
        });
        break;
      case 'customerEmail':
        this._customersCollection = this._afs.collection('companies', ref => {
          return ref.where('customerStreet', '==' , this.customersService.customerSearchString);
        });
        break;
      case 'customerPhoneNumber':
        this._customersCollection = this._afs.collection('companies', ref => {
          return ref.where('customerPhoneNumber', '==' , this.customersService.customerSearchString);
        });
        break;
      case 'customerNIP':
        this._customersCollection = this._afs.collection('companies', ref => {
          return ref.where('customerNIP', '==' , this.customersService.customerSearchString);
        });
        break;
    }
    this.customers$ = this._customersCollection.valueChanges().pipe(
      map(data => {
        this.length = data.length;
        this.loading = false;
        return data;
      })
    );
  }

}
