import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { OrderData } from '../../models/order.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public loading: boolean;
  public orders$: Observable<Array<OrderData>>;
  public payableOrders$: Observable<Array<OrderData>>;
  public warrantyOrders$: Observable<Array<OrderData>>;
  private _ordersCollection: AngularFirestoreCollection<OrderData>;

  constructor(private _afs: AngularFirestore, private _authService: AuthService) {
    this.loading = true;
    this._ordersCollection = this._afs.collection<OrderData>('orders', ref => {
      return ref
        .where('orderStatus', '>=', 1)
        .where('orderStatus', '<=', 6)
        .orderBy('orderStatus', 'asc')
        .orderBy('orderYear', 'desc')
        .orderBy('orderMonth', 'desc')
        .orderBy('sortNumber', 'desc')
        .limit(150);
    });

    this.orders$ = this._ordersCollection.valueChanges().pipe(
      map(data => {
        console.log(data.length);
        this.loading = false;
        return data;
      })
    );
    this.payableOrders$ = this.orders$.pipe(
      map(data => {
        return data.filter((value: OrderData) => value.orderType === 'payable');
      })
    );
    this.warrantyOrders$ = this.orders$.pipe(
      map(data => {
        return data.filter((value: OrderData) => value.orderType === 'warranty');
      })
    );
  }

  public filterByDate(dataSource$: Observable<any>, orders, filterFC): void {
    dataSource$ = dataSource$.pipe(
      map(data => {
        return data.filter(
          (value: OrderData) =>
            value.orderNumber.toLowerCase().includes(filterFC.toLowerCase()) ||
            (value.orderCity === 'Leszno' &&
              value.orderDate.toLowerCase().includes(filterFC.toLowerCase())) ||
            (value.orderCity === 'Leszno' &&
              value.orderType.toLowerCase().includes(filterFC.toLowerCase())) ||
            (value.orderCity === 'Leszno' &&
              value.device.deviceType.toLowerCase().includes(filterFC.toLowerCase())) ||
            (value.orderCity === 'Leszno' &&
              value.customer.customerName.toLowerCase().includes(filterFC.toLowerCase())) ||
            (value.orderCity === 'Leszno' &&
              value.servicemanName.toLowerCase().includes(filterFC.toLowerCase()))
        );
      })
    );
  }
}
