import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AuthService } from '../../services/auth-service/auth.service';
import { InformationsComponent } from '../informations/informations.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  /**
   * Form control made for email
   * @type {FormControl}
   * @memberof LoginComponent
   */
  public emailFC: FormControl = new FormControl('', Validators.required);

  /**
   * Form control made for password
   * @type {FormControl}
   * @memberof LoginComponent
   */
  public passwordFC: FormControl = new FormControl('', Validators.required);

  /**
   * Boolean that toggles visibility of password in form
   * @type {boolean}
   * @memberof LoginComponent
   */
  public hide: boolean;

  constructor(public authService: AuthService, private _matDialog: MatDialog) { }

  /**
   * Passes information from form to Auth Service and inside of Auth Service loges in user
   * @param {string} email user's email
   * @param {string} password user's password
   * @memberof LoginComponent
   */
  public signIn(email: string, password: string): void {
    this.authService.emailSignIn(email, password);
    this.emailFC.reset();
    this.passwordFC.reset();
  }

  public openPolicy() {
    this._matDialog.open(InformationsComponent);
  }

  ngOnInit() {
    this.hide = true;
  }
}
