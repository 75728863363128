import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from 'angularfire2/firestore';
import { CustomerData } from '../../models/customer.model';
import { Observable } from 'rxjs';
import { SweetAlertResult } from 'sweetalert2';
import swal from 'sweetalert2';
import * as firebase from 'firebase';
import { FormControl, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  /**
   * setting to true when some operations are being made on firestore data for example deleting, adding
   * @type {boolean}
   * @memberof CustomersService
   */
  public loading: boolean;

  /**
   * Collection of customers documents inside of firestore
   * @private
   * @type {AngularFirestoreCollection<CustomerData>}
   * @memberof CustomersService
   */
  private _customersCollection: AngularFirestoreCollection<CustomerData>;

  /**
   * customer type that indicates if customer is either individual or company, based on this variable works radio buttons
   * accross `payable-order.component.html`, `warranty-order.component.html` and `customer.component.html` with their ts components
   * @type {string}
   * @memberof CustomersService
   */
  public customerType: string;

  /**
   * ID of selected user on which we can later make some changes to selected user doc inside firestore e.g. update, delete, etc...
   * @type {string}
   * @memberof CustomersService
   */
  public selectedCustomerID: string;

  /**
   * Selected user object that later on we'll use to for example fill in users inputs in `customer.component.html`
   * @memberof CustomersService
   */
  public selectedCustomer = {
    customerID: <string>'',
    customerName: <string>'',
    customerStreet: <string>'',
    customerPostalCode: <string>'',
    customerCity: <string>'',
    customerPhoneNumber: <string>'',
    customerEmail: <string>'',
    customerNIP: <string>'',
    customerOrders: <Array<string>>[]
  };

  /**
   * Array with orders of selected customer
   * @type {*}
   * @memberof CustomersService
   */
  public selectedCustomerOrders: any;

  public customerFormFields = {
    customerID: <AbstractControl>undefined,
    customerName: <AbstractControl>undefined,
    customerStreet: <AbstractControl>undefined,
    customerPostalCode: <AbstractControl>undefined,
    customerCity: <AbstractControl>undefined,
    customerPhoneNumber: <AbstractControl>undefined,
    customerEmail: <AbstractControl>undefined,
    customerNIP: <AbstractControl>undefined
  };

  public customerDialogShortVersion: boolean;

  /**
   * Boolean that indicates either if customer is in editing state or in state of creation new user, it's used in
   * `customer.component.html` to switch between buttons, placeholders, etc...
   * @type {boolean}
   * @memberof CustomersService
   */
  public editingCustomer: boolean;

  public customerSearchTerms: string;
  public customerSearchString: string;

  public individualCustomersOrder$: Observable<CustomerData[]>;
  public companyCustomersOrder$: Observable<CustomerData[]>;

  constructor(
    private _afs: AngularFirestore,
    private _snackBar: MatSnackBar,
    private _matDialog: MatDialog
  ) {
    this._customersCollection = this._afs.collection('companies');
    this.customerType = 'individual';
    this.customerSearchTerms = 'customerName';
  }

  /**
   * Creation customer's doc inside fitestore with material snack bar popup and dismissing opened material dialog
   * with `customer.component.html`, if customer is being added from order html view then it triggers some other functionalites
   * to update user's doc, order's doc and so on
   * @param {CustomerData} customer customer values from inputs
   * @param {boolean} [addFromOrder] OPTIONAL set to false when user is being created from order view
   * @param {string} [orderID] OPTIONAL ID of order that is ment to be updated with user creation
   * @memberof CustomersService
   */
  public addCustomer(
    customer: CustomerData,
    addFromOrder?: boolean,
    orderID?: string
  ): void {
    let NIP: string = customer.customerNIP;
    let email = customer.customerEmail;
    this.loading = true;
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoID = '';
    for (let i = 0; i < 20; i++) {
      autoID += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    if (NIP === null) {
      NIP = '';
    }
    if (email === null) {
      email = '';
    }
    this._customersCollection
      .doc(autoID)
      .set({
        customerID: autoID,
        customerName: customer.customerName,
        customerStreet: customer.customerStreet,
        customerPostalCode: customer.customerPostalCode,
        customerCity: customer.customerCity,
        customerPhoneNumber: customer.customerPhoneNumber,
        customerEmail: email,
        customerNIP: NIP,
        customerOrders: [],
        customerType: this.customerType
      })
      .then(() => {
        this._showFeedbackToUser('Klient został dodany ✔');
        if (addFromOrder === true) {
          this._addOrderToCustomer(autoID, orderID);
          this._updateOrder(autoID, customer.customerName, orderID);
        }
      })
      .catch(err => {
        this.loading = false;
        this._handleError(err);
      });
  }

  public setCustomer() {
    this.customerFormFields.customerID.setValue(this.selectedCustomerID);
    this.customerFormFields.customerName.setValue(
      this.selectedCustomer.customerName
    );
    this.customerFormFields.customerStreet.setValue(
      this.selectedCustomer.customerStreet
    );
    this.customerFormFields.customerCity.setValue(
      this.selectedCustomer.customerCity
    );
    this.customerFormFields.customerPostalCode.setValue(
      this.selectedCustomer.customerPostalCode
    );
    this.customerFormFields.customerPhoneNumber.setValue(
      this.selectedCustomer.customerPhoneNumber
    );
    this.customerFormFields.customerEmail.setValue(
      this.selectedCustomer.customerEmail
    );
    this.customerFormFields.customerNIP.setValue(
      this.selectedCustomer.customerNIP
    );
    this._matDialog.closeAll();
  }

  /**
   * Deletes client's doc from firestore and displaying material snack bar popup
   * @param {string} customerID
   * @memberof CustomersService
   */
  public deleteCustomer(customerID: string): void {
    swal({
      title: 'Czy jesteś pewnien?',
      text: 'Ta operacja jest nieodwracalna!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Usuń!',
      cancelButtonText: 'Anuluj'
    }).then(result => {
      if (result.value) {
        this._customersCollection
          .doc(customerID)
          .delete()
          .then(() => {
            this._showFeedbackToUser('Klient został usunięty ❌');
          })
          .catch(err => {
            this._handleError(err);
          });
      }
    });
  }

  public editCustomer(customer: CustomerData): void {
    this.loading = true;
    let NIP: string;
    let email = customer.customerEmail;
    if (this.customerType === 'individual') {
      NIP = '';
    } else {
      NIP = customer.customerNIP;
    }
    if (email === null) {
      email = '';
    }
    this._customersCollection
      .doc(this.selectedCustomerID)
      .update({
        customerName: customer.customerName,
        customerStreet: customer.customerStreet,
        customerPostalCode: customer.customerPostalCode,
        customerCity: customer.customerCity,
        customerPhoneNumber: customer.customerPhoneNumber,
        customerEmail: email,
        customerNIP: NIP,
        customerType: this.customerType
      })
      .then(() => {
        this._showFeedbackToUser('Klient został zaktualizowany ✔');
        for (let i = 0; i < this.selectedCustomerOrders.length; i++) {
          console.log(this.selectedCustomerOrders[i]);
        }
      })
      .catch(err => {
        this.loading = false;
        this._handleError(err);
      });
  }

  public selectCustomerForSearch(customer: CustomerData) {
    this.selectedCustomerID = customer.customerID;
    this.selectedCustomer = {
      customerID: customer.customerID,
      customerName: customer.customerName,
      customerStreet: customer.customerStreet,
      customerPostalCode: customer.customerPostalCode,
      customerCity: customer.customerCity,
      customerPhoneNumber: customer.customerPhoneNumber,
      customerEmail: customer.customerEmail,
      customerNIP: customer.customerNIP,
      customerOrders: customer.customerOrders
    };
    this.setCustomer();
  }

  public selectCustomer(customer: CustomerData): void {
    this.editingCustomer = true;
    this.selectedCustomerID = customer.customerID;
    this.customerType = customer.customerType;
    this.selectedCustomer = {
      customerID: customer.customerID,
      customerName: customer.customerName,
      customerStreet: customer.customerStreet,
      customerPostalCode: customer.customerPostalCode,
      customerCity: customer.customerCity,
      customerPhoneNumber: customer.customerPhoneNumber,
      customerEmail: customer.customerEmail,
      customerNIP: customer.customerNIP,
      customerOrders: customer.customerOrders
    };
    this._selectCustomerOrders(customer);
  }

  public deselectCustomer(): void {
    this.editingCustomer = false;
    this.selectedCustomerID = undefined;
    this.customerType = 'individual';
    this.selectedCustomerOrders = [];
    this.selectedCustomer = {
      customerID: '',
      customerName: '',
      customerStreet: '',
      customerPostalCode: '',
      customerCity: '',
      customerPhoneNumber: '',
      customerEmail: '',
      customerNIP: '',
      customerOrders: []
    };
  }

  private _selectCustomerOrders(customer: CustomerData): void {
    this.selectedCustomerOrders = [];
    for (let i = 0; i < customer.customerOrders.length; i++) {
      this.selectedCustomerOrders[i] = this._afs
        .collection('orders')
        .doc(customer.customerOrders[i])
        .valueChanges();
    }
  }

  public checkIfExists(customer: CustomerData, orderID: string): void {
    let numberOfRepeats: number;
    let customersCollection$: Observable<any>;
    numberOfRepeats = 0;
    customersCollection$ = this._afs
      .collection('companies', ref => {
        return ref
          .where('customerName', '==', customer.customerName)
          .where('customerStreet', '==', customer.customerStreet)
          .where('customerPostalCode', '==', customer.customerPostalCode)
          .where('customerCity', '==', customer.customerCity)
          .where('customerPhoneNumber', '==', customer.customerPhoneNumber);
      })
      .valueChanges();
    customersCollection$.subscribe(data => {
      while (numberOfRepeats === 0) {
        if (data.length === 0) {
          this.addCustomer(customer, true, orderID);
        } else if (data.length > 0) {
          this._addOrderToCustomer(data[0].customerID, orderID);
          this._updateOrder(data[0].customerID, customer.customerName, orderID);
        }
        numberOfRepeats++;
      }
    });
  }

  private _updateOrder(
    customerID: string,
    customerName: string,
    orderID: string
  ) {
    this._afs
      .collection('orders')
      .doc(orderID)
      .update({
        customer: {
          customerID: customerID,
          customerName: customerName
        }
      })
      .catch(err => {
        this._handleError(err);
      });
  }

  private _addOrderToCustomer(customerID: string, orderID: string): void {
    this._afs
      .collection('companies')
      .doc(customerID)
      .update({
        customerOrders: firebase.firestore.FieldValue.arrayUnion(orderID)
      })
      .catch(err => {
        this._handleError(err);
      });
  }

  private _showFeedbackToUser(message: string): void {
    this.loading = false;
    this._snackBar.open(message);
    setTimeout(() => {
      this._snackBar.dismiss();
    }, 2000);
    this._matDialog.closeAll();
  }

  /**
   * Returns error with swal
   * @private
   * @param {*} err incoming error
   * @returns {Promise<SweetAlertResult>} swal modal popup
   * @memberof CustomersService
   */
  private _handleError(err: any): Promise<SweetAlertResult> {
    return swal({
      type: 'error',
      title: err.code,
      text: err.message
    });
  }
}
