import { Component, OnInit } from '@angular/core';
import { OrdersService } from '../../services/orders-service/orders.service';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { OrderData } from '../../models/order.model';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { OrderComponent } from '../order/order.component';
import { PrintService } from '../../services/print-service/print.service';
import { PrintComponent } from '../print/print.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-calendar-day',
  templateUrl: './calendar-day.component.html',
  styleUrls: ['./calendar-day.component.scss']
})
export class CalendarDayComponent implements OnInit {

  public loading: boolean;
  public length: number;
  private _ordersCollection: AngularFirestoreCollection<OrderData>;
  public orders$: Observable<Array<OrderData>>;

  constructor(public ordersService: OrdersService, private _afs: AngularFirestore, private _matDialog: MatDialog,
    private _printService: PrintService) { }

  public openDialog(orderID: string) {
    this._matDialog.open(OrderComponent, {
      minWidth: 1100,
      maxHeight: 705
    });
    this.ordersService.selectOrder(orderID);
  }

  public printOrder(data: OrderData, customerCopy: boolean) {
    this._printService.selectToPrint(data, customerCopy);
    this._matDialog.open(PrintComponent, {
      maxHeight: 700,
      maxWidth: 500,
      panelClass: 'custom-modal-dialog'
    });
  }

  ngOnInit() {
    this.loading = true;
    this._ordersCollection = this._afs.collection('orders', ref => {
      return ref.where('orderDate', '==', this.ordersService.calendarDay).orderBy('sortNumber', 'desc');
    });
    this.orders$ = this._ordersCollection.valueChanges().pipe(
      map(data => {
        this.length = data.length;
        this.loading = false;
        return data;
      })
    );
  }

}
