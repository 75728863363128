import { Component, OnInit, OnDestroy } from '@angular/core';
import {ViewEncapsulation } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AuthService } from '../../services/auth-service/auth.service';
import { OrdersService } from '../../services/orders-service/orders.service';
import { CustomersService } from '../../services/customers-service/customers.service';
import { DevicesService } from '../../services/devices-service/devices.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { CustomerData } from '../../models/customer.model';
import { OrderData } from '../../models/order.model';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data-service/data.service';
import { PagesService } from '../../services/pages-service/pages.service';
import { PrintService } from '../../services/print-service/print.service';
import { PrintComponent } from '../print/print.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderComponent implements OnInit, OnDestroy {

  public order: any;
  public customer: FormGroup;
  public device: any;
  public receiver: FormGroup;
  public customerType: string;
  public cityServiceman: Observable<OrderData>;
  public disabled: boolean;

  constructor(public ordersService: OrdersService, private _formBuilder: FormBuilder, public customersService: CustomersService,
    public devicesService: DevicesService, private _afs: AngularFirestore, public authService: AuthService,
    private _matDialog: MatDialog, public dataService: DataService, public pagesService: PagesService,
    private _printService: PrintService) { }

  get orderStatus(): AbstractControl {
    return this.order.get('orderStatus');
  }

  get orderCity(): AbstractControl {
    return this.order.get('orderCity');
  }

  get partsPrice(): AbstractControl {
    return this.order.get('partsPrice');
  }

  get servicedAtLeszno(): AbstractControl {
    return this.order.get('servicedAtLeszno');
  }

  get customerID(): AbstractControl {
    return this.customer.get('customerID');
  }

  get customerName(): AbstractControl {
    return this.customer.get('customerName');
  }

  get deviceID(): AbstractControl {
    return this.device.get('deviceID');
  }

  get deviceCatalogNumber(): AbstractControl {
    return this.device.get('deviceCatalogNumber');
  }

  get deviceType(): AbstractControl {
    return this.device.get('deviceType');
  }

  get deviceSellDate(): AbstractControl {
    return this.device.get('deviceSellDate');
  }

  get deviceSellDocumentNumber(): AbstractControl {
    return this.device.get('deviceSellDocumentNumber');
  }

  get deviceTechnicalCondition(): AbstractControl {
    return this.device.get('deviceTechnicalCondition');
  }

  get deviceConditionNotes(): AbstractControl {
    return this.device.get('deviceConditionNotes');
  }

  get faultDescription(): AbstractControl {
    return this.device.get('faultDescription');
  }

  get notes(): AbstractControl {
    return this.device.get('notes');
  }

  get receiverName(): AbstractControl {
    return this.receiver.get('receiverName');
  }

  get servicemanName(): AbstractControl {
    return this.receiver.get('servicemanName');
  }

  private _addDeviceEquipmentGroup(name: string): FormGroup {
    return this._formBuilder.group({
      equipmentName: [name]
    });
  }

  private _initEquipmentType(): FormGroup {
    return this._formBuilder.group({
      equipmentName: ['']
    });
  }

  private _addOrderPartsGroup(partName: string, partQuantity: string, partPrice: string): FormGroup {
    return this._formBuilder.group({
      partName: [partName],
      partQuantity: [partQuantity]
    });
  }

  private _initParts(): FormGroup {
    return this._formBuilder.group({
      partName: [],
      partQuantity: ['0']
    });
  }

  private _addDeviceEquipment(name: string) {
    const device: FormArray = <FormArray>this.device.controls['deviceEquipment'];
    const addEquipmentType: FormGroup = this._addDeviceEquipmentGroup(name);
    device.push(addEquipmentType);
  }

  private _addOrderParts(partDetails: any) {
    const partInfo: FormArray = <FormArray>this.order.controls['orderParts'];
    const addPartsInfo: FormGroup = this._addOrderPartsGroup(partDetails.partName, partDetails.partQuantity, partDetails.partPrice);
    partInfo.push(addPartsInfo);
  }

  public addEquipmentType(): void {
    const device: FormArray = <FormArray>this.device.controls['deviceEquipment'];
    const addEquipmentType: FormGroup = this._initEquipmentType();
    device.push(addEquipmentType);
  }

  public removeEquipmentType(): void {
    const index: number = this.device.value.deviceEquipment.length;
    const device: FormArray = <FormArray>this.device.controls['deviceEquipment'];
    device.removeAt(index - 1);
  }

  public addOrderParts(): void {
    const partsInfo: FormArray = <FormArray>this.order.controls['orderParts'];
    const addPartsInfo: FormGroup = this._initParts();
    partsInfo.push(addPartsInfo);
  }

  public removeOrderParts(): void {
    const index: number = this.order.value.orderParts.length;
    const order: FormArray = <FormArray>this.order.controls['orderParts'];
    order.removeAt(index - 1);
  }

  public selectCustomerName(customerName: string): void {
    this.customerName.setValue(customerName);
  }

  public selectDeviceCatalogNumber(deviceCatalogNumber: string): void {
    this.deviceCatalogNumber.setValue(deviceCatalogNumber);
  }

  public closePanel() {
    this._matDialog.closeAll();
  }

  public editOrder() {
    this.ordersService.editOrder(this.order.value, this.customer.value, this.device.value, this.receiver.value);
  }

  public printOrder(data: OrderData, customerCopy: boolean): void {
    this._printService.selectToPrint(data, customerCopy);
    this._matDialog.closeAll();
    this._matDialog.open(PrintComponent, {
      maxHeight: 700,
      maxWidth: 500,
      panelClass: 'custom-modal-dialog'
    });
  }

  ngOnInit() {
    this.ordersService.selectedOrder$.subscribe(data => {
      let city = data.orderCity;
      if (city === 'Jelenia góra') {
        city = 'Jelenia_góra';
      }
      if (!data.servicedAtLeszno) {
        this.cityServiceman = this._afs.collection('users').doc<OrderData>(city).valueChanges();
      } else {
        this.cityServiceman = this._afs.collection('users').doc<OrderData>('Leszno').valueChanges();
      }
      this._afs.collection('companies').doc<CustomerData>(data.customer.customerID).valueChanges().subscribe(value => {
        this.customerType = value.customerType;
      });
      this.order = this._formBuilder.group({
        'orderStatus': [data.orderStatus, [Validators.required]],
        'orderCity': [data.orderCity, [Validators.required]],
        'servicedAtLeszno': [data.servicedAtLeszno],
        'partsPrice': [data.partsPrice, [
          Validators.required,
          Validators.pattern('[0-9]*[.][0-9]{2}')
        ]],
        'orderParts': this._formBuilder.array([
          this._formBuilder.group({
            partName: [data.orderParts[0].partName],
            partQuantity: [data.orderParts[0].partQuantity]
          })
        ]),
      });

      this.customer = this._formBuilder.group({
        'customerID': [data.customer.customerID, [Validators.required]],
        'customerName': [data.customer.customerName]
      });
      this.device = this._formBuilder.group({
        'deviceID': [data.device.deviceID],
        'deviceCatalogNumber': [data.device.deviceCatalogNumber, [Validators.required]],
        'deviceSerialNumber': [data.deviceSerialNumber],
        'deviceType': [data.device.deviceType],
        'deviceSellDate': [data.deviceSellDate],
        'deviceSellDocumentNumber': [data.deviceSellDocumentNumber],
        'deviceEquipment': this._formBuilder.array([
          this._formBuilder.group({
            equipmentName: [data.deviceEquipment[0]]
          })
        ]),
        'deviceTechnicalCondition': [data.deviceTechnicalState, [Validators.required]],
        'deviceConditionNotes': [data.deviceConditionNotes],
        'faultDescription': [data.faultDescription, [Validators.required]],
        'notes': [data.notes],
        'additionalInformations': [data.additionalInformations]
      });

      this.receiver = this._formBuilder.group({
        'receiverName': [data.receiverName],
        'servicemanName': [data.servicemanName]
      });

      for (let i = 1; i < data.deviceEquipment.length; i++) {
        this._addDeviceEquipment(data.deviceEquipment[i]);
      }

      for (let i = 1; i < data.orderParts.length; i++) {
        this._addOrderParts(data.orderParts[i]);
      }

      if (data.orderStatus >= 7) {
        this.disabled = true;
        this.order.disable();
        this.customer.disable();
        this.device.disable();
        this.receiver.disable();
      }
    });
  }

  ngOnDestroy(): void {
    this.pagesService.showArrow = false;
  }

}
