export const columnNames = [
  'Nazwa',
  'Ulica',
  'Kod pocztowy',
  'Miasto',
  'Numer telefonu',
  'Email',
  'NIP',
  'usun'
];
