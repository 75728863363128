import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { AuthService } from '../auth-service/auth.service';
import { Observable } from 'rxjs';
import { OrderData } from '../../models/order.model';
import { CustomersService } from '../customers-service/customers.service';
import { DevicesService } from '../devices-service/devices.service';
import { CustomerData } from '../../models/customer.model';
import { SweetAlertResult } from 'sweetalert2';
import * as _ from 'lodash';
import * as firebase from 'firebase';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  public loading: boolean;
  private _ordersCollection: AngularFirestoreCollection<OrderData>;
  public selectedOrder$: Observable<OrderData>;
  public selectedOrderID: string;
  public calendarDay: string;

  constructor(
    private _afs: AngularFirestore,
    private _snackBar: MatSnackBar,
    private _customersService: CustomersService,
    private _devicesService: DevicesService,
    private _matDialog: MatDialog,
    private _authService: AuthService
  ) {
    this._ordersCollection = this._afs.collection('orders');
  }

  public addPayableOrder(order: any, customer: any, device: any, receiver: any) {
    this.loading = true;
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    if (receiver.servicedAtLeszno === true || receiver.servicemanName === null) {
      receiver.servicemanName = '';
    }
    for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    this._devicesService.checkIfExists(device, autoId);
    this._customersService.checkIfExists(customer, autoId);
    this._ordersCollection
      .doc(autoId)
      .set({
        orderID: autoId,
        sortNumber: order.orderSortNumber,
        orderNumber: order.orderNumber,
        orderType: 'payable',
        orderDate: order.orderDate,
        orderEndDate: '',
        orderCity: order.orderCity,
        orderMonth: parseInt(order.orderDate.substring(3, 5), 0),
        orderYear: parseInt(order.orderDate.substring(6, 10), 0),
        partsPrice: '0.00',
        orderParts: [
          {
            partName: '',
            partQuantity: '0'
          }
        ],
        servicedAtLeszno: receiver.servicedAtLeszno,
        orderStatus: 1,
        customer: {
          customerID: '',
          customerName: ''
        },
        device: {
          deviceID: '',
          deviceType: '',
          deviceCatalogNumber: ''
        },
        deviceEquipment: [],
        deviceSerialNumber: device.deviceSerialNumber,
        receiverName: receiver.receiverName,
        servicemanName: receiver.servicemanName,
        deviceConditionNotes: device.conditionNotes,
        deviceTechnicalState: device.technicalCondition,
        deviceTechnicalNotes: device.notes,
        faultDescription: device.faultDescription,
        notes: device.notes,
        additionalInformations: device.additionalInformations
      })
      .then(() => {
        this.loading = false;
        this._snackBar.open('Zlecenie zostało dodane ✔');
        this._checkIfDescriptionExists(device.faultDescription);
        this._customersService.checkIfExists(customer, autoId);
        this._devicesService.checkIfExists(device, autoId);
        setTimeout(() => {
          this._snackBar.dismiss();
        }, 2000);
      })
      .catch(err => {
        this.loading = false;
        this._handleError(err);
      });
  }

  public addWarrantyOrder(order: any, customer: any, device: any, receiver: any) {
    this.loading = true;
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    if (receiver.servicedAtLeszno === true || receiver.servicemanName === null) {
      receiver.servicemanName = '';
    }
    for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    this._ordersCollection
      .doc(autoId)
      .set({
        orderID: autoId,
        sortNumber: order.orderSortNumber,
        orderNumber: order.orderNumber,
        orderType: 'warranty',
        orderDate: order.orderDate,
        orderEndDate: '',
        orderCity: order.orderCity,
        orderMonth: parseInt(order.orderDate.substring(3, 5), 0),
        orderYear: parseInt(order.orderDate.substring(6, 10), 0),
        partsPrice: '0.00',
        orderParts: [
          {
            partName: '',
            partQuantity: '0'
          }
        ],
        servicedAtLeszno: receiver.servicedAtLeszno,
        orderStatus: 1,
        customer: {
          customerID: '',
          customerName: ''
        },
        device: {
          deviceID: '',
          deviceType: '',
          deviceCatalogNumber: ''
        },
        deviceEquipment: [],
        deviceSerialNumber: device.deviceSerialNumber,
        deviceSellDate: device.deviceSellDate,
        deviceSellDocumentNumber: device.deviceSellDocumentNumber,
        receiverName: receiver.receiverName,
        servicemanName: receiver.servicemanName,
        deviceConditionNotes: device.conditionNotes,
        deviceTechnicalState: device.technicalCondition,
        deviceTechnicalNotes: device.notes,
        faultDescription: device.faultDescription,
        notes: device.notes,
        additionalInformations: device.additionalInformations
      })
      .then(() => {
        this.loading = false;
        this._showFeedbackToUser('Zlecenie zostało dodane ✔');
        this._checkIfDescriptionExists(device.faultDescription);
        this._customersService.checkIfExists(customer, autoId);
        this._devicesService.checkIfExists(device, autoId);
      })
      .catch(err => {
        this.loading = false;
        this._handleError(err);
      });
  }

  public editOrder(order: any, customer: any, device: any, receiver: any) {
    this.loading = true;
    const equipment = this._filterDeviceEquipment(device.deviceEquipment);
    let parts = this._filterOrderParts(order.orderParts);
    if (parts[0] === undefined) {
      parts = [
        {
          partName: '',
          partQuantity: '0'
        }
      ];
    }
    this._afs
      .collection('orders')
      .doc(this.selectedOrderID)
      .update({
        orderCity: order.orderCity,
        partsPrice: order.partsPrice,
        orderParts: parts,
        servicedAtLeszno: order.servicedAtLeszno,
        customer: {
          customerID: customer.customerID,
          customerName: customer.customerName
        },
        device: {
          deviceID: device.deviceID,
          deviceCatalogNumber: device.deviceCatalogNumber,
          deviceType: device.deviceType
        },
        deviceEquipment: equipment,
        deviceSerialNumber: device.deviceSerialNumber,
        deviceSellDate: device.deviceSellDate,
        deviceSellDocumentNumber: device.deviceSellDocumentNumber,
        servicemanName: receiver.servicemanName,
        deviceConditionNotes: device.deviceConditionNotes,
        deviceTechnicalState: device.deviceTechnicalCondition,
        deviceTechnicalNotes: device.notes,
        faultDescription: device.faultDescription,
        notes: device.notes,
        additionalInformations: device.additionalInformations
      })
      .then(() => {
        this.loading = false;
        this._showFeedbackToUser('Zlecenie zostało edytowane ✔');
        this.editStatus(this.selectedOrderID, order.orderStatus);
      })
      .catch(err => {
        this.loading = false;
        this._handleError(err);
      });
  }

  public editCity(order: OrderData, city: string): void {
    this._ordersCollection
      .doc(order.orderID)
      .update({
        orderCity: city,
        servicemanName: ''
      })
      .then(() => {
        this._showFeedbackToUser('Miasto zostało zaktualizowane ✔');
      })
      .catch(err => {
        this._handleError(err);
      });
  }

  public editStatus(orderID: string, orderStatus: number): void {
    let day: string = new Date().getDate().toString();
    let month: string = (new Date().getMonth() + 1).toString();
    const year: number = new Date().getFullYear();

    if (orderStatus === 7 || orderStatus === 8) {
      if (day.length === 1) {
        day = `0${day}`;
      }
      if (month.length === 1) {
        month = `0${month}`;
      }
      swal({
        title: 'Czy jesteś pewnien?',
        text:
          'Zmieniając status zlecenia na naprawiono lub klient zrezygnował nie mozna go później edytować oraz ponownie zmieniać statusu!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#f44336',
        confirmButtonText: 'Zmień!',
        cancelButtonText: 'Anuluj'
      }).then(result => {
        if (result.value) {
          this._ordersCollection
            .doc(orderID)
            .update({
              orderStatus: orderStatus,
              orderEndDate: `${day}-${month}-${year}`
            })
            .then(() => {
              this._showFeedbackToUser('Status zamówienia został zaktualizowany ✔');
            })
            .catch(err => {
              this._handleError(err);
            });
        }
      });
    } else {
      if (orderStatus === 5 || orderStatus === 6) {
        this._ordersCollection
          .doc(orderID)
          .update({
            orderStatus: orderStatus,
            orderEndDate: `${day}-${month}-${year}`
          })
          .then(() => {
            this._showFeedbackToUser('Status zamówienia został zaktualizowany ✔');
          })
          .catch(err => {
            this._handleError(err);
          });
      } else {
        this._ordersCollection
          .doc(orderID)
          .update({
            orderStatus: orderStatus
          })
          .then(() => {
            this._showFeedbackToUser('Status zamówienia został zaktualizowany ✔');
          })
          .catch(err => {
            this._handleError(err);
          });
      }
    }
  }

  public selectOrder(orderID: string): void {
    this.selectedOrderID = orderID;
    this.selectedOrder$ = this._afs
      .collection('orders')
      .doc<OrderData>(orderID)
      .valueChanges();
  }

  public deleteOrder(orderID: string, customerID: string): void {
    swal({
      title: 'Czy jesteś pewnien?',
      text: 'Operacja usunięcia zlecenia jest nieodwracalna!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Usuń!',
      cancelButtonText: 'Anuluj'
    }).then(result => {
      if (result.value) {
        this._afs
          .collection('orders')
          .doc(orderID)
          .delete()
          .then(() => {
            this._showFeedbackToUser('Zamówienie zostało usunięte ❌');
            this._afs
              .collection('companies')
              .doc(customerID)
              .update({
                customerOrders: firebase.firestore.FieldValue.arrayRemove(orderID)
              })
              .catch(err => this._handleError(err));
          })
          .catch(err => {
            this._handleError(err);
          });
      }
    });
  }

  private _checkIfDescriptionExists(description: string) {
    let descriptionsCollection$: Observable<any>;
    let numberOfRepeats = 0;
    descriptionsCollection$ = this._afs
      .collection('descriptions', ref => {
        return ref.where('description', '==', description);
      })
      .valueChanges();
    descriptionsCollection$.subscribe(data => {
      while (numberOfRepeats === 0) {
        if (data.length === 0) {
          this._addDescription(description);
        }
        numberOfRepeats++;
      }
    });
  }

  private _addDescription(description: string) {
    this._afs
      .collection('descriptions')
      .add({
        description: description
      })
      .catch(err => {
        this._handleError(err);
      });
  }

  private _filterDeviceEquipment(equipmentUnfiltered: Array<any>): Array<string> {
    let equipment: Array<string> = [];
    if (equipmentUnfiltered[0].equipmentName !== undefined) {
      for (let i = 0; i < equipmentUnfiltered.length; i++) {
        if (equipment[i] === undefined) {
          equipment[i] = equipmentUnfiltered[i].equipmentName;
        }
      }
    }
    equipment = _.compact(equipment);
    equipment = _.uniq(equipment);
    return equipment;
  }

  private _filterOrderParts(partsUnfiltered: Array<any>): Array<any> {
    const tempParts: Array<any> = [];
    const partsToReturn: Array<any> = [];
    const validIndexes: Array<number> = [];
    for (let i = 0; i < partsUnfiltered.length; i++) {
      tempParts[i] = partsUnfiltered[i];
    }
    for (let i = 0; i < tempParts.length; i++) {
      if (
        tempParts[i].partName !== null &&
        tempParts[i].partName !== '' &&
        tempParts[i].partQuantity !== NaN &&
        tempParts[i].partQuantity !== ''
      ) {
        validIndexes.push(i);
      }
    }
    for (let i = 0; i < validIndexes.length; i++) {
      partsToReturn.push(tempParts[validIndexes[i]]);
    }
    return partsToReturn;
  }

  private _showFeedbackToUser(message: string): void {
    this._snackBar.open(message);
    this._matDialog.closeAll();
    setTimeout(() => {
      this._snackBar.dismiss();
    }, 2000);
  }

  private _handleError(err: any): Promise<SweetAlertResult> {
    return swal({
      type: 'error',
      title: err.code,
      text: err.message
    });
  }
}
