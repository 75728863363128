import { Component, OnInit } from '@angular/core';
import { DevicesService } from 'src/app/services/devices-service/devices.service';
import { MatDialog } from '@angular/material';
import { DeviceSearchComponent } from '../device-search/device-search.component';

@Component({
  selector: 'app-device-order-search',
  templateUrl: './device-order-search.component.html',
  styleUrls: ['./device-order-search.component.scss']
})
export class DeviceOrderSearchComponent implements OnInit {

  public searchInputsValues = {
    deviceType: '',
    deviceCatalogNumber: '',
    deviceEquipment: ''
  };

  constructor(public devicesService: DevicesService, private _matDialog: MatDialog) { }

  openSearchDialog(searchParameter: string) {
    let searchString;
    switch (searchParameter) {
      case 'deviceType':
        searchString = this.searchInputsValues.deviceType;
        break;
      case 'deviceCatalogNumber':
        searchString = this.searchInputsValues.deviceCatalogNumber;
        break;
      case 'deviceEquipment':
        searchString = this.searchInputsValues.deviceEquipment;
        break;
    }
    this.devicesService.deviceSearchString = searchString;
    this.devicesService.deviceSearchTerms = searchParameter;
    this._matDialog.closeAll();
    this._matDialog.open(DeviceSearchComponent, {
      maxHeight: 700,
      maxWidth: 500
    });
  }

  ngOnInit() {
  }

}
