import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { OrderComponent } from '../order/order.component';
import { DataService } from '../../services/data-service/data.service';
import { AuthService } from '../../services/auth-service/auth.service';
import { OrdersService } from '../../services/orders-service/orders.service';
import { columnNames } from './home.data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderData } from '../../models/order.model';
import { triggerColumn } from '../../animations/triggerColumn.animation';
import { PagesService } from '../../services/pages-service/pages.service';
import { PrintService } from '../../services/print-service/print.service';
import { PrintComponent } from '../print/print.component';
import { AngularFirestore } from 'angularfire2/firestore';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [triggerColumn]
})
export class HomeComponent implements OnInit, OnDestroy {

  public initialLoading: boolean;
  public loading: boolean;
  private _orders$: Observable<Array<OrderData>>;
  public dataSource$: Observable<Array<OrderData>>;
  public dataSourceLeszno$: Observable<Array<OrderData>>;
  public dataSourceWroclaw$: Observable<Array<OrderData>>;
  public dataSourceGlogow$: Observable<Array<OrderData>>;
  public dataSourceJelenia$: Observable<Array<OrderData>>;
  public dataSourceLegnica$: Observable<Array<OrderData>>;
  public columns: Array<string> = columnNames;
  public date: Date;
  public day: string;
  public month: string;
  public expandedElement: OrderData;
  public orderStatus: FormControl = new FormControl('', [Validators.required]);
  /**
   * Form control made to filter dataSource$
   * @type {FormControl}
   * @memberof CustomersComponent
   */
  public filterFC: FormControl = new FormControl('', [Validators.required]);

  constructor(public authService: AuthService, public ordersService: OrdersService, private _matDialog: MatDialog,
    public dataService: DataService, private _pagesService: PagesService, private _printService: PrintService, private _afs: AngularFirestore) { }

  public applyFilter(): void {
    this.initialLoading = true;
    this.dataSourceLeszno$ = this._orders$.pipe(
      map(data => {
        this.initialLoading = false;
        return data.filter((value: OrderData) => value.orderCity === 'Leszno' &&
          value.orderNumber.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Leszno' &&
          value.orderDate.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Leszno' &&
          value.orderType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Leszno' &&
          value.device.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Leszno' &&
          value.customer.customerName.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Leszno' &&
          value.servicemanName.toLowerCase().includes(this.filterFC.value.toLowerCase()));
      })
    );

    this.dataSourceWroclaw$ = this._orders$.pipe(
      map(data => {
        this.initialLoading = false;
        return data.filter((value: OrderData) => value.orderCity === 'Wrocław' &&
          value.orderNumber.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Wrocław' &&
          value.orderDate.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Wrocław' &&
          value.orderType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Wrocław' &&
          value.device.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Wrocław' &&
          value.customer.customerName.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Wrocław' &&
          value.servicemanName.toLowerCase().includes(this.filterFC.value.toLowerCase()));
      })
    );

    this.dataSourceGlogow$ = this._orders$.pipe(
      map(data => {
        this.initialLoading = false;
        return data.filter((value: OrderData) => value.orderCity === 'Głogów' &&
          value.orderNumber.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Głogów' &&
          value.orderDate.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Głogów' &&
          value.orderType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Głogów' &&
          value.device.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Głogów' &&
          value.customer.customerName.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Głogów' &&
          value.servicemanName.toLowerCase().includes(this.filterFC.value.toLowerCase()));
      })
    );

    this.dataSourceLegnica$ = this._orders$.pipe(
      map(data => {
        this.initialLoading = false;
        return data.filter((value: OrderData) => value.orderCity === 'Legnica' &&
          value.orderNumber.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Legnica' &&
          value.orderDate.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Legnica' &&
          value.orderType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Legnica' &&
          value.device.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Legnica' &&
          value.customer.customerName.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Legnica' &&
          value.servicemanName.toLowerCase().includes(this.filterFC.value.toLowerCase()));
      })
    );

    this.dataSourceJelenia$ = this._orders$.pipe(
      map(data => {
        this.initialLoading = false;
        return data.filter((value: OrderData) => value.orderCity === 'Jelenia_góra' &&
          value.orderNumber.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Jelenia_góra' &&
          value.orderDate.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Jelenia_góra' &&
          value.orderType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Jelenia_góra' &&
          value.device.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Jelenia_góra' &&
          value.customer.customerName.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === 'Jelenia_góra' &&
          value.servicemanName.toLowerCase().includes(this.filterFC.value.toLowerCase()));
      })
    );

    this.dataSource$ = this._orders$.pipe(
      map(data => {
        this.initialLoading = false;
        return data.filter((value: OrderData) => value.orderCity === this.authService.userCity &&
          value.orderNumber.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === this.authService.userCity &&
          value.orderDate.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === this.authService.userCity &&
          value.orderType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === this.authService.userCity &&
          value.device.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === this.authService.userCity &&
          value.customer.customerName.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
          value.orderCity === this.authService.userCity &&
          value.servicemanName.toLowerCase().includes(this.filterFC.value.toLowerCase()));
      })
    );
  }

  public printOrder(data: OrderData, customerCopy: boolean): void {
    this._printService.selectToPrint(data, customerCopy);
    this._matDialog.open(PrintComponent, {
      maxHeight: 700,
      maxWidth: 500,
      panelClass: 'custom-modal-dialog'
    });
  }

  public cancelFilter() {
    this.initialLoading = true;
    if (this.authService.userCity !== 'Leszno') {
      this.dataSource$ = this._orders$.pipe(
        map(data => {
          this.initialLoading = false;
          return data.filter((value: OrderData) => value.orderCity === this.authService.userCity &&
            value.orderStatus > 0 && value.orderStatus < 7);
        })
      );
    } else {
      this._getLeszno();
      this._getGłogów();
      this._getWrocław();
      this._getJelenia();
      this._getLegnica();
    }
    this.filterFC.reset();
  }

  public setOrderValue(order: string): void {
    this.orderStatus.setValue(order);
  }

  public editStatus(orderID: string) {
    this.ordersService.editStatus(orderID, this.orderStatus.value);
  }

  public openDialog(orderID: string): void {
    this._matDialog.open(OrderComponent, {
      minWidth: 1100,
      maxHeight: 705
    });
    this.ordersService.selectOrder(orderID);
  }

  public dragOrder(data, city): void {
    this.ordersService.editCity(data.dragData, city);
  }

  private _getDay(): string {
    const days = ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'];
    return days[new Date().getDay()];
  }

  private _getMonth(): string {
    const months = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec',
      'sierpień', 'wrzesień', 'październik', 'listopad' , 'grudzień'];
    return months[new Date().getMonth()];
  }

  private _getLeszno() {
    this.dataSourceLeszno$ = this._orders$.pipe(
      map(data => {
        this.initialLoading = false;
        return data.filter((value: OrderData) => value.orderCity === 'Leszno' &&
          value.orderStatus > 0 && value.orderStatus < 7);
      })
    );
  }

  private _getWrocław(): void {
    this.dataSourceWroclaw$ = this._orders$.pipe(
      map(data => {
        return data.filter((value: OrderData) => value.orderCity === 'Wrocław' &&
          value.orderStatus > 0 && value.orderStatus < 7);
      })
    );
  }

  private _getGłogów(): void {
    this.dataSourceGlogow$ = this._orders$.pipe(
      map(data => {
        return data.filter((value: OrderData) => value.orderCity === 'Głogów' &&
          value.orderStatus > 0 && value.orderStatus < 7);
      })
    );
  }

  private _getJelenia(): void {
    this.dataSourceJelenia$ = this._orders$.pipe(
      map(data => {
        return data.filter((value: OrderData) => value.orderCity === 'Jelenia_góra' &&
          value.orderStatus > 0 && value.orderStatus < 7);
      })
    );
  }

  private _getLegnica(): void {
    this.dataSourceLegnica$ = this._orders$.pipe(
      map(data => {
        return data.filter((value: OrderData) => value.orderCity === 'Legnica' &&
          value.orderStatus > 0 && value.orderStatus < 7);
      })
    );
  }

  ngOnInit() {
    this.initialLoading = true;
    this._orders$ = this.dataService.orders$;
    if (this.authService.userCity !== 'Leszno') {
      this.dataSource$ = this._orders$.pipe(
        map(data => {
          this.initialLoading = false;
          return data.filter((value: OrderData) => value.orderCity === this.authService.userCity &&
            value.orderStatus > 0 && value.orderStatus < 7);
        })
      );
    } else {
      this._getLeszno();
      this._getGłogów();
      this._getWrocław();
      this._getJelenia();
      this._getLegnica();
    }
    this.date = new Date();
    this.day = this._getDay();
    this.month = this._getMonth();
    this._pagesService.selectedHome = true;
  }

  ngOnDestroy() {
    this._pagesService.selectedHome = false;
  }

}
