export const columnNames = [
  'Miejsce zamówienia',
  'Numer zamówienia',
  'Data zamówienia',
  'Typ zamówienia',
  'Nazwa klienta',
  'Nazwa urządzenia',
  'Osoba serwisująca',
  'Status zamówienia'
];
