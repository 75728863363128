import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { OrderComponent } from '../order/order.component';
import { AuthService } from '../../services/auth-service/auth.service';
import { CustomersService } from '../../services/customers-service/customers.service';
import { DevicesService } from '../../services/devices-service/devices.service';
import { OrdersService } from '../../services/orders-service/orders.service';
import { OrderData } from '../../models/order.model';
import { CustomerData } from '../../models/customer.model';
import { DeviceData } from '../../models/device.model';
import { columnNames } from './warranty-order.data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from 'angularfire2/firestore';
import { MatDialog } from '@angular/material';
import { DataService } from '../../services/data-service/data.service';
import { PagesService } from '../../services/pages-service/pages.service';
import { triggerColumn } from '../../animations/triggerColumn.animation';
import { PrintComponent } from '../print/print.component';
import { PrintService } from '../../services/print-service/print.service';
import { CustomerOrderSearchComponent } from '../customer-order-search/customer-order-search.component';
import { DeviceOrderSearchComponent } from '../device-order-search/device-order-search.component';

@Component({
  selector: 'app-warranty-order',
  templateUrl: './warranty-order.component.html',
  styleUrls: ['./warranty-order.component.scss'],
  animations: [triggerColumn]
})
export class WarrantyOrderComponent implements OnInit, OnDestroy {

  /**
   * Form control made to filter dataSource$
   * @type {FormControl}
   * @memberof CustomersComponent
   */
  public filterFC: FormControl = new FormControl('', [Validators.required]);
  public order: FormGroup;
  public customer: FormGroup;
  public device: FormGroup;
  public receiver: FormGroup;
  public dataSource$: Observable<any>;
  public columns: Array<string> = columnNames;
  public expandedElement: OrderData;
  public orderStatus: FormControl = new FormControl('', [Validators.required]);
  public deviceSelectEq: Array<string> = [];
  public deviceSelected: boolean;

  constructor(private _formBuilder: FormBuilder, public customersService: CustomersService, public devicesService: DevicesService,
    public authService: AuthService, public ordersService: OrdersService, private _afs: AngularFirestore, private _matDialog: MatDialog,
    public dataService: DataService, public pagesService: PagesService, private _printService: PrintService) { }

  // Set of getters to get values from form controls
  get orderNumber(): AbstractControl {
    return this.order.get('orderNumber');
  }
  get orderDate(): AbstractControl {
    return this.order.get('orderDate');
  }

  get orderSortNumber(): AbstractControl {
    return this.order.get('orderSortNumber');
  }

  get customerID(): AbstractControl {
    return this.customer.get('customerID');
  }

  get customerName(): AbstractControl {
    return this.customer.get('customerName');
  }

  get customerStreet(): AbstractControl {
    return this.customer.get('customerStreet');
  }

  get customerPostalCode(): AbstractControl {
    return this.customer.get('customerPostalCode');
  }

  get customerCity(): AbstractControl {
    return this.customer.get('customerCity');
  }

  get customerPhoneNumber(): AbstractControl {
    return this.customer.get('customerPhoneNumber');
  }

  get customerEmail(): AbstractControl {
    return this.customer.get('customerEmail');
  }

  get customerNIP(): AbstractControl {
    return this.customer.get('customerNIP');
  }

  get deviceID(): AbstractControl {
    return this.device.get('deviceID');
  }

  get deviceSellDate(): AbstractControl {
    return this.device.get('deviceSellDate');
  }

  get deviceSellDocumentNumber(): AbstractControl {
    return this.device.get('deviceSellDocumentNumber');
  }

  get deviceCatalogNumber(): AbstractControl {
    return this.device.get('deviceCatalogNumber');
  }

  get deviceSerialNumber(): AbstractControl {
    return this.device.get('deviceSerialNumber');
  }

  get deviceType(): AbstractControl {
    return this.device.get('deviceType');
  }

  get technicalCondition(): AbstractControl {
    return this.device.get('technicalCondition');
  }

  get conditionNotes(): AbstractControl {
    return this.device.get('conditionNotes');
  }

  get deviceEquipment(): AbstractControl {
    return this.device.get('deviceEquipment');
  }

  get notes(): AbstractControl {
    return this.device.get('notes');
  }

  get faultDescription(): AbstractControl {
    return this.device.get('faultDescription');
  }

  get receiverName(): AbstractControl {
    return this.receiver.get('receiverName');
  }

  get servicemanName(): AbstractControl {
    return this.receiver.get('servicemanName');
  }

  get servicedAtLeszno(): AbstractControl {
    return this.receiver.get('servicedAtLeszno');
  }
  public openSearchDialog() {
    this.customersService.customerDialogShortVersion = true;
    this._matDialog.open(CustomerOrderSearchComponent, {
      maxHeight: 700,
      maxWidth: 500
    });
  }

  public openSearchDeviceDialog() {
    this.devicesService.deviceDialogShortVersion = true;
    this.devicesService.deviceDialogShortVersion = true;
    this._matDialog.open(DeviceOrderSearchComponent, {
      maxHeight: 700
    });
  }

  public setOrderValue(order: string): void {
    this.orderStatus.setValue(order);
  }

  public editStatus(orderID: string) {
    this.ordersService.editStatus(orderID, this.orderStatus.value);
  }

  public printOrder(data: OrderData, customerCopy: boolean) {
    this._printService.selectToPrint(data, customerCopy);
    this._matDialog.open(PrintComponent, {
      maxHeight: 700,
      maxWidth: 500,
      panelClass: 'custom-modal-dialog'
    });
  }

  addWarantyOrder() {
    const orderData = {
      orderNumber: this.orderNumber.value,
      orderDate: this.orderDate.value,
      orderCity: this.order.controls['orderCity'].value,
      orderSortNumber: this.orderSortNumber.value
    };
    this.ordersService.addWarrantyOrder(orderData, this.customer.value, this.device.value, this.receiver.value);
  }

  public selectCustomer(customer: CustomerData) {
    this.customerID.setValue(customer.customerID);
    this.customerStreet.setValue(customer.customerStreet);
    this.customerPostalCode.setValue(customer.customerPostalCode);
    this.customerCity.setValue(customer.customerCity);
    this.customerPhoneNumber.setValue(customer.customerPhoneNumber);
    this.customerEmail.setValue(customer.customerEmail);
    this.customerNIP.setValue(customer.customerNIP);
  }

  public selectDevice(device: DeviceData) {
    this.deviceSelected = true;
    this.deviceID.setValue(device.deviceID);
    this.deviceEquipment.reset();
    this.deviceSelectEq = device.deviceEquipment;
  }

  public trackDeviceForm(value: string) {
    if (value === '') {
      this.deviceSelected = false;
    }
  }

  public clean() {
    this.customerName.reset();
    this.customerStreet.reset();
    this.customerPostalCode.reset();
    this.customerCity.reset();
    this.customerPhoneNumber.reset();
    this.customerEmail.reset();
    this.customerNIP.reset();
    this.servicedAtLeszno.setValue(false);
  }

  public clearForm() {
    this.customer.reset();
    this.device.reset();
    this.receiver.reset();
    this.servicedAtLeszno.setValue(false);
    this.deviceSelected = false;
  }

  public openPanel(): void {
    this.pagesService.expanderExpanded = true;
  }

  public closePanel(): void {
    this.pagesService.expanderExpanded = false;
  }

  public openDialog(orderID: string): void {
    this._matDialog.open(OrderComponent, {
      minWidth: 1100,
      maxHeight: 705
    });
    this.ordersService.selectOrder(orderID);
  }

  public applyFilter(): void {
    if (this.authService.userCity === 'Leszno') {
      this.dataSource$ = this.dataService.orders$.pipe(
        map(data => {
          return data.filter((value: OrderData) => value.customer.customerName.toLowerCase().includes(this.filterFC.value.toLowerCase())
            && value.orderType === 'warranty' ||
            value.device.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase())
            && value.orderType === 'warranty' ||
            value.orderNumber.toLowerCase().includes(this.filterFC.value.toLowerCase())
            && value.orderType === 'warranty' ||
            value.orderDate.toLowerCase().includes(this.filterFC.value.toLowerCase())
            && value.orderType === 'warranty' ||
            value.servicemanName.toLowerCase().includes(this.filterFC.value.toLowerCase())
            && value.orderType === 'warranty'
          );
        })
      );
    } else {
      this.dataSource$ = this.dataService.orders$.pipe(
        map(data => {
          return data.filter((value: OrderData) => value.customer.customerName.toLowerCase().includes(this.filterFC.value.toLowerCase())
            && value.orderType === 'warranty'
            && value.orderCity === this.authService.userCity ||
            value.device.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase())
            && value.orderType === 'warranty'
            && value.orderCity === this.authService.userCity ||
            value.orderNumber.toLowerCase().includes(this.filterFC.value.toLowerCase())
            && value.orderType === 'warranty'
            && value.orderCity === this.authService.userCity ||
            value.orderDate.toLowerCase().includes(this.filterFC.value.toLowerCase())
            && value.orderType === 'warranty'
            && value.orderCity === this.authService.userCity ||
            value.servicemanName.toLowerCase().includes(this.filterFC.value.toLowerCase())
            && value.orderType === 'warranty'
            && value.orderCity === this.authService.userCity
          );
        })
      );
    }
  }

  public cancelFilter() {
    if (this.authService.userCity === 'Leszno') {
      this.dataSource$ = this.dataService.orders$.pipe(
        map(data => {
          return data.filter((value: OrderData) => value.orderType === 'warranty');
        })
      );
    } else {
      this.dataSource$ = this.dataService.orders$.pipe(
        map(data => {
          return data.filter((value: OrderData) => value.orderCity === this.authService.userCity &&
            value.orderType === 'warranty');
        })
      );
    }
    this.filterFC.reset();
  }

  ngOnInit() {
    this.deviceSelected = false;
    this.pagesService.expanderExpanded = false;
    if (this.authService.userCity === 'Leszno') {
      this.dataSource$ = this.dataService.orders$.pipe(
        map(data => {
          return data.filter((value: OrderData) => value.orderType === 'warranty');
        })
      );
    } else {
      this.dataSource$ = this.dataService.orders$.pipe(
        map(data => {
          return data.filter((value: OrderData) => value.orderCity === this.authService.userCity &&
            value.orderType === 'warranty');
        })
      );
    }
    let day: string = new Date().getDate().toString();
    let month: string = (new Date().getMonth() + 1).toString();
    if (day.length === 1) {
      day = `0${day}`;
    }
    if (month.length === 1) {
      month = `0${month}`;
    }
    let city: string;
    switch (this.authService.userCity) {
      case 'Wrocław':
        city = 'WR';
        break;
      case 'Legnica':
        city = 'LG';
        break;
      case 'Jelenia_góra':
        city = 'JG';
        break;
      case 'Głogów':
        city = 'GL';
        break;
      default:
        city = 'LE';
        break;
    }
    this._afs.collection<OrderData>('orders', ref => {
      return ref.where('orderMonth', '==', new Date().getMonth() + 1).where('orderYear', '==', new Date().getFullYear())
      .where('orderCity', '==', this.authService.userCity);
      }).valueChanges().subscribe(data => {
      let monthCount = 0;
      let sortNumber = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].orderDate.substring(3, 5) === month &&
          data[i].orderCity === this.authService.userCity && data[i].orderType === 'warranty') {
          monthCount++;
        }
        if (data[i].orderCity === this.authService.userCity) {
          sortNumber++;
        }
      }
      this.orderNumber.setValue(`${monthCount + 1}/${new Date().getMonth() + 1}/G/${city}/${new Date().getFullYear()}`);
      this.orderSortNumber.setValue(sortNumber);
    });
    this.order = this._formBuilder.group({
      'orderNumber': ['', [Validators.required]],
      'orderDate': [`${day}-${month}-${new Date().getFullYear()}`, [Validators.required]],
      'orderCity': [this.authService.userCity],
      'orderSortNumber': ['']
    });
    this.customer = this._formBuilder.group({
      'customerID': [''],
      'customerName': ['', [Validators.required]],
      'customerStreet': ['', [Validators.required]],
      'customerPostalCode': ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6)
      ]],
      'customerCity': ['', [Validators.required]],
      'customerPhoneNumber': ['', [
        Validators.required,
        Validators.pattern('^[0-9]{3}-?[0-9]{3}-?[0-9]{3}$')
      ]],
      'customerEmail': [''],
      'customerNIP': ['']
    });
    this.device = this._formBuilder.group({
      'deviceID': [''],
      'deviceSellDate': ['', [Validators.required]],
      'deviceSellDocumentNumber': ['', [Validators.required]],
      'deviceCatalogNumber': ['', [Validators.required]],
      'deviceSerialNumber': ['', [Validators.required]],
      'deviceType': ['', [Validators.required]],
      'technicalCondition': ['', [Validators.required]],
      'conditionNotes': ['', [Validators.required]],
      'deviceEquipment': ['', [Validators.required]],
      'notes': [''],
      'faultDescription': ['', [Validators.required]],
      'additionalInformations': ['']
    });

    this.receiver = this._formBuilder.group({
      'receiverName': [''],
      'servicemanName': [''],
      'servicedAtLeszno': [false]
    });

    if (this.pagesService.navigatedFromCustomer === true) {
      this.customerID.setValue(this.pagesService.customerData.customerID);
      this.customerName.setValue(this.pagesService.customerData.customerName);
      this.customerStreet.setValue(this.pagesService.customerData.customerStreet);
      this.customerPostalCode.setValue(this.pagesService.customerData.customerPostalCode);
      this.customerCity.setValue(this.pagesService.customerData.customerCity);
      this.customerPhoneNumber.setValue(this.pagesService.customerData.customerPhoneNumber);
      this.customerEmail.setValue(this.pagesService.customerData.customerEmail);
      this.customerNIP.setValue(this.pagesService.customerData.customerNIP);
    }

    if (this.pagesService.navigatedFromDevice === true) {
      this.deviceID.setValue(this.pagesService.deviceData.deviceID);
      this.deviceCatalogNumber.setValue(this.pagesService.deviceData.deviceCatalogNumber);
      this.deviceType.setValue(this.pagesService.deviceData.deviceType);
      this.deviceEquipment.setValue(this.pagesService.deviceData.deviceEquipment);
    }
    this.pagesService.selectedOrder = true;

    this.orderDate.disable();
    this.orderNumber.disable();

    this.customersService.customerFormFields.customerID = this.customerID;
    this.customersService.customerFormFields.customerName = this.customerName;
    this.customersService.customerFormFields.customerStreet = this.customerStreet;
    this.customersService.customerFormFields.customerPostalCode = this.customerPostalCode;
    this.customersService.customerFormFields.customerCity = this.customerCity;
    this.customersService.customerFormFields.customerEmail = this.customerEmail;
    this.customersService.customerFormFields.customerPhoneNumber = this.customerPhoneNumber;
    this.customersService.customerFormFields.customerNIP = this.customerNIP;

    this.devicesService.deviceFormFields.deviceID = this.deviceID;
    this.devicesService.deviceFormFields.deviceType = this.deviceType;
    this.devicesService.deviceFormFields.deviceCatalogNumber = this.deviceCatalogNumber;
    this.devicesService.deviceFormFields.deviceEquipment = this.deviceEquipment;
  }

  ngOnDestroy() {
    this.pagesService.expanderExpanded = false;
    this.pagesService.customerData = {
      customerID: '',
      customerName: '',
      customerStreet: '',
      customerPostalCode: '',
      customerCity: '',
      customerPhoneNumber: '',
      customerEmail: '',
      customerNIP: '',
      customerOrders: [],
      customerType: ''
    };
    this.pagesService.deviceData = {
      deviceID: '',
      deviceCatalogNumber: '',
      deviceEquipment: [],
      deviceType: ''
    };
    this.pagesService.selectedOrder = false;
  }
}
