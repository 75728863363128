import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AngularFirestore } from 'angularfire2/firestore';
import { OrderComponent } from '../order/order.component';
import { OrdersService } from '../../services/orders-service/orders.service';
import { CustomersService } from '../../services/customers-service/customers.service';
import { DevicesService } from '../../services/devices-service/devices.service';
import { AuthService } from '../../services/auth-service/auth.service';
import { columnNames } from './orders.data';
import { OrderData } from '../../models/order.model';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DataService } from '../../services/data-service/data.service';
import { PagesService } from '../../services/pages-service/pages.service';
import { PrintService } from '../../services/print-service/print.service';
import { PrintComponent } from '../print/print.component';
import { triggerColumn } from '../../animations/triggerColumn.animation';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  animations: [triggerColumn]
})
export class OrdersComponent implements OnInit, OnDestroy {

  /**
   * Form control made to filter dataSource$
   * @type {FormControl}
   * @memberof CustomersComponent
   */
  public filterFC: FormControl = new FormControl('', [Validators.required]);

  public initialLoading: boolean;
  public loading;
  private _orders$: Observable<Array<OrderData>>;
  public dataSource$: Observable<any>;
  public columns: Array<string> = columnNames;
  public expandedElement: OrderData;
  public orderStatus: FormControl = new FormControl('', [Validators.required]);

  constructor(public ordersService: OrdersService, public authService: AuthService, public customersService: CustomersService,
    public devicesService: DevicesService, private _matDialog: MatDialog, public dataService: DataService,
    private _pagesService: PagesService, private _printService: PrintService) { }

  public openDialog(orderID: string) {
    this._matDialog.open(OrderComponent, {
      minWidth: 1100,
      maxHeight: 705
    });
    this.ordersService.selectOrder(orderID);
  }

  public editStatus(orderID: string) {
    this.ordersService.editStatus(orderID, this.orderStatus.value);
  }

  public printOrder(data: OrderData, customerCopy: boolean) {
    this._printService.selectToPrint(data, customerCopy);
    this._matDialog.open(PrintComponent, {
      maxHeight: 700,
      maxWidth: 500,
      panelClass: 'custom-modal-dialog'
    });
  }

  public filterByCity(city: string): void {
    this.initialLoading = true;
    this.dataSource$ = this._orders$.pipe(
      map(data => {
        this.initialLoading = false;
        return data.filter((value: OrderData) => value.orderCity === city);
      })
    );
  }

  public setOrderValue(order: string): void {
    this.orderStatus.setValue(order);
  }

  public cancelFilteringByCity() {
    this.dataSource$ = this._orders$;
  }

  public applyFilter(): void {
    this.initialLoading = true;
    if (this.authService.userCity === 'Leszno') {
      this.dataSource$ = this._orders$.pipe(
        map(data => {
          this.initialLoading = false;
          return data.filter((value: OrderData) => value.orderNumber.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
            value.orderDate.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
            value.orderType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
            value.device.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
            value.customer.customerName.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
            value.servicemanName.toLowerCase().includes(this.filterFC.value.toLowerCase()));
        })
      );
    } else {
      this.dataSource$ = this._orders$.pipe(
        map(data => {
          this.initialLoading = false;
          return data.filter((value: OrderData) => value.orderCity === this.authService.userCity &&
            value.orderNumber.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
            value.orderCity === this.authService.userCity &&
            value.orderDate.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
            value.orderCity === this.authService.userCity &&
            value.orderType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
            value.orderCity === this.authService.userCity &&
            value.device.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
            value.orderCity === this.authService.userCity &&
            value.customer.customerName.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
            value.orderCity === this.authService.userCity &&
            value.servicemanName.toLowerCase().includes(this.filterFC.value.toLowerCase()));
        })
      );
    }
  }

  public cancelFilter() {
    this.initialLoading = true;
    if (this.authService.userCity === 'Leszno') {
      this.dataSource$ = this.dataService.orders$;
      this.initialLoading = false;
    } else {
      this.dataSource$ = this.dataService.orders$.pipe(
        map(data => {
          this.initialLoading = false;
          return data.filter((value: OrderData) => value.orderCity === this.authService.userCity);
        })
      );
    }
    this.filterFC.reset();
  }

  ngOnInit() {
    this._orders$ = this.dataService.orders$;
    if (this.authService.userCity === 'Leszno') {
      this.initialLoading = true;
      this.dataSource$ = this.dataService.orders$.pipe(
        map(data => {
          this.initialLoading = false;
          return data;
        })
      );
    } else {
      this.initialLoading = true;
      this.dataSource$ = this.dataService.orders$.pipe(
        map(data => {
          this.initialLoading = false;
          return data.filter((value: OrderData) => value.orderCity === this.authService.userCity);
        })
      );
    }
    this._pagesService.selectedOrder = true;
  }

  ngOnDestroy() {
    this._pagesService.selectedOrder = false;
  }
}
