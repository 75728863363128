import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { UserData } from '../../models/user.model';
import { SweetAlertResult } from 'sweetalert2';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class UserDataService {

  /**
   * Set loading to true when changing phone number to trigger mat-progress-bar in `user-settings.component.html`
   * @type {boolean}
   * @memberof UserDataService
   */
  public loading: boolean;

  /**
   * user's document to further updates
   * @type {AngularFirestoreDocument<UserData>}
   * @memberof UserDataService
   */
  private _userDoc: AngularFirestoreDocument<UserData>;

  constructor(private _snackBar: MatSnackBar, private _afs: AngularFirestore) {}

  /**
   * Method that gets informations about user from firestore when is able to get values from collection `usersCity/${userData.email}`
   * @param {string} city user's city in which he is located, user passed this info at the user sign in operation
   * @memberof UserDataService
   */
  public getUserDoc(city: string) {
    this._userDoc = this._afs.collection('users').doc<UserData>(city);
  }

  /**
   * changing phone numbers of members of certain karcher company in given city, method combains information about users and
   * their phone numbers into one big object that then passes to firestore to update data
   * @param {*} phoneNumbers new phone numbers from Form Array in `user-settings.component`
   * @param {Array<any>} members members of given karcher company with their not updated phone numbers and names
   * @memberof UserDataService
   */
  public changePhoneNumbers(phoneNumbers: any, members: Array<any>) {
    this.loading = true;
    for (let i = 0; i < members.length; i++) {
      members[i].phoneNumber = phoneNumbers.memberPhoneNumber[i].phoneNumber;
    }
    this._userDoc.update({
      users: members
    })
      .then(() => {
        this.loading = false;
        this._showFeedbackToUser('Numery telefonów zostały zaktualizowane ✔');
      })
      .catch(err => {
        this.loading = false;
        this._handleError(err);
      });
  }

  /**
   * Show snakc bar popup to aware user about changes
   * @private
   * @param {string} message info about changes
   * @memberof UserDataService
   */
  private _showFeedbackToUser(message: string): void {
    this._snackBar.open(message);
    setTimeout(() => {
      this._snackBar.dismiss();
    }, 2000);
  }

  /**
   * Returns error with swal
   * @private
   * @param {*} err incoming error
   * @returns {Promise<SweetAlertResult>} swal modal popup
   * @memberof UserDataService
   */
  private _handleError(err: any): Promise<SweetAlertResult> {
    return swal({
      type: 'error',
      title: err.code,
      text: err.message
    });
  }
}
