import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DevicesService } from '../../services/devices-service/devices.service';
import { PagesService } from '../../services/pages-service/pages.service';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  /**
   * Group form that contains all details about device, values of this form group
   * are taken from `devices.service.ts`
   * @type {*}
   * @memberof DeviceComponent
   */
  public device: any;

  constructor(private _formBuilder: FormBuilder, public devicesService: DevicesService, public pagesService: PagesService) { }

  // Set of getters to get values from form controls

  get deviceCatalogNumber(): AbstractControl {
    return this.device.get('deviceCatalogNumber');
  }

  get deviceType(): AbstractControl {
    return this.device.get('deviceType');
  }

  /**
   * Initialize form group with equipmentName as only member of this group, used later to make array form of those small
   * form groups
   * @private
   * @returns {FormGroup} Form group with '' value
   * @memberof DeviceComponent
   */
  private _initEquipmentType(): FormGroup {
    return this._formBuilder.group({
      equipmentName: ['']
    });
  }

  /**
   * Initialize form group with equipmentName as only member of this group with `i` value of selected device equipment,
   *  used later to make array form of those small form groups
   * @private
   * @param {string} name `i` value of selected device equipment name
   * @returns {FormGroup} Form group with `name` value
   * @memberof DeviceComponent
   */
  private _selectEquipmentType(name: string): FormGroup {
    return this._formBuilder.group({
      equipmentName: [name]
    });
  }

  /**
   * adds x number (length of `devicesService.selectedDevice.deviceEquipment`) amount of small form groups with
   * assigned to them values to one huge form group that is going to be displayed later to the user
   * @private
   * @param {string} name `i` value of selected device equipment name
   * @memberof DeviceComponent
   */
  private _addSelectedEquipmentType(name: string): void {
    const device: FormArray = <FormArray>this.device.controls['deviceEquipment'];
    const addEquipmentType: FormGroup = this._selectEquipmentType(name);
    device.push(addEquipmentType);
  }

  /**
   * adds form group without any value to one huge form group that is going to be displayed later to the user
   * @memberof DeviceComponent
   */
  public addEquipmentType(): void {
    const device: FormArray = <FormArray>this.device.controls['deviceEquipment'];
    const addEquipmentType: FormGroup = this._initEquipmentType();
    device.push(addEquipmentType);
  }

  /**
   * removes form group from superior form group
   * @memberof DeviceComponent
   */
  public removeEquipmentType(): void {
    const index: number = this.device.value.deviceEquipment.length;
    const device: FormArray = <FormArray>this.device.controls['deviceEquipment'];
    device.removeAt(index - 1);
  }

  /**
   * adds device to firestore collection by invoking `deviceService.addDevice(value)` method
   * as value it passes all values from `device` form group
   * @memberof DeviceComponent
   */
  public addDevice(): void {
    this.devicesService.addDevice(this.device.value);
  }

  /**
   * edits device in firestore by invoking `devicesService.editDevice(value)` method
   * as value it passes all values from `device` form group
   * @memberof DeviceComponent
   */
  public editDevice(): void {
    this.devicesService.editDevice(this.device.value);
  }

  ngOnInit(): void {
    this.device = this._formBuilder.group({
      deviceCatalogNumber: [this.devicesService.selectedDevice.deviceCatalogNumber, [Validators.required]],
      deviceType: [this.devicesService.selectedDevice.deviceType, [Validators.required]],
      deviceEquipment: this._formBuilder.array([
        this._formBuilder.group({
          equipmentName: [this.devicesService.selectedDevice.deviceEquipment[0]]
        })
      ])
    });
    for (let i = 1; i < this.devicesService.selectedDevice.deviceEquipment.length; i++) {
      this._addSelectedEquipmentType(this.devicesService.selectedDevice.deviceEquipment[i]);
    }
  }
}
