import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CalendarDayComponent } from '../calendar-day/calendar-day.component';
import { OrdersService } from '../../services/orders-service/orders.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  private _days: Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public months: Array<string> = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień',
    'wrzesień', 'październik', 'listopad', 'grudzień'];
  public monthIndex: number;
  public year: number;
  public day: number;
  public date: Date = new Date();
  public selectedDate: any = {
    day: 1,
    month: 1,
    year: 1
  };

  public namesDaysInMonth: Array<string>;
  public firstDaysInMonth: Array<number>;
  public lastDaysInMonth: Array<number>;

  constructor(private _ordersService: OrdersService, private _matDialog: MatDialog) {}

  public showDay(day: number, month: number, year: number): void {
    if (month === 13) {
      year = year + 1;
      month = 1;
    } else if ( month === 0) {
      year = year - 1;
      month = 12;
    }
    this.selectedDate = {
      day: `${day}`,
      month: `${month}`,
      year: `${year}`
    };
  }

  public filterByDate() {
    if (this.selectedDate.day.length === 1) {
      this.selectedDate.day = `0${this.selectedDate.day}`;
    }
    if (this.selectedDate.month.length === 1) {
      this.selectedDate.month = `0${this.selectedDate.month}`;
    }
    this._ordersService.calendarDay = `${this.selectedDate.day}-${this.selectedDate.month}-${this.selectedDate.year}`;
    this._matDialog.open(CalendarDayComponent, {
      maxHeight: 700,
      minWidth: 500
    });
  }

  public monthBack(): void {
    if (this.monthIndex === 0) {
      this.monthIndex = 11;
      this.year--;
    } else {
      this.monthIndex--;
    }
    this.getDaysInMonth();
    this._getDaysNamesInCertainMonth();
    this._getNextMonthDays();
    this._getPreviousMonthDays();
  }

  public monthForward(): void {
    if (this.monthIndex === 11) {
      this.monthIndex = 0;
      this.year++;
    } else {
      this.monthIndex++;
    }
    this.getDaysInMonth();
    this._getDaysNamesInCertainMonth();
    this._getNextMonthDays();
    this._getPreviousMonthDays();
  }

  public getDaysInMonth(): Array<number> {
    const numberOfDays: Array<number> = [];
    for (let i = 1; i <= new Date(this.year, this.monthIndex + 1, 0).getDate(); i++) {
      numberOfDays.push(i);
    }
    return numberOfDays;
  }

  private _getDaysNamesInCertainMonth(): void {
    this.namesDaysInMonth = [];
    for (let i = 1; i <= new Date(this.year, this.monthIndex + 1, 0).getDate(); i++) {
      this.namesDaysInMonth.push(this._days[new Date(this.year, this.monthIndex, i).getDay()]);
    }
  }

  private _getPreviousMonthDays(): void {
    this.firstDaysInMonth = [];
    switch (this.namesDaysInMonth[0]) {
      case 'Tuesday':
        for (let i = new Date(this.year, this.monthIndex, 0).getDate();
          i < new Date(this.year, this.monthIndex, 0).getDate() + 1; i++) {
          this.firstDaysInMonth.push(i);
        }
        break;

      case 'Wednesday':
        for (let i = new Date(this.year, this.monthIndex, 0).getDate() - 1;
          i < new Date(this.year, this.monthIndex, 0).getDate() + 1; i++) {
          this.firstDaysInMonth.push(i);
        }
        break;

      case 'Thursday':
        for (let i = new Date(this.year, this.monthIndex, 0).getDate() - 2;
          i < new Date(this.year, this.monthIndex, 0).getDate() + 1; i++) {
          this.firstDaysInMonth.push(i);
        }
        break;

      case 'Friday':
        for (let i = new Date(this.year, this.monthIndex, 0).getDate() - 3;
          i < new Date(this.year, this.monthIndex, 0).getDate() + 1; i++) {
          this.firstDaysInMonth.push(i);
        }
        break;

      case 'Saturday':
        for (let i = new Date(this.year, this.monthIndex, 0).getDate() - 4;
          i < new Date(this.year, this.monthIndex, 0).getDate() + 1; i++) {
          this.firstDaysInMonth.push(i);
        }
        break;

      case 'Sunday':
        for (let i = new Date(this.year, this.monthIndex, 0).getDate() - 5;
          i < new Date(this.year, this.monthIndex, 0).getDate() + 1; i++) {
          this.firstDaysInMonth.push(i);
        }
        break;

      default:
        this.firstDaysInMonth = [];
        break;
    }
  }

  private _getNextMonthDays(): void {
    this.lastDaysInMonth = [];
    switch (this.namesDaysInMonth[new Date(this.year, this.monthIndex + 1, 0).getDate() - 1]) {
      case 'Monday':
        this.lastDaysInMonth.push(1, 2, 3, 4, 5, 6);
        break;
      case 'Tuesday':
        this.lastDaysInMonth.push(1, 2, 3, 4, 5);
        break;
      case 'Wednesday':
        this.lastDaysInMonth.push(1, 2, 3, 4);
        break;
      case 'Thursday':
        this.lastDaysInMonth.push(1, 2, 3);
        break;
      case 'Friday':
        this.lastDaysInMonth.push(1, 2);
        break;
      case 'Saturday':
        this.lastDaysInMonth.push(1);
        break;
      default:
        this.lastDaysInMonth = [];
        break;
    }
  }

  ngOnInit() {
    this.day = new Date().getDate();
    this.monthIndex = new Date().getMonth();
    this.year = new Date().getFullYear();
    this.getDaysInMonth();
    this._getDaysNamesInCertainMonth();
    this._getNextMonthDays();
    this._getPreviousMonthDays();
  }

}
