import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserData } from '../../models/user.model';
import { MatDialog } from '@angular/material';
import * as firebase from 'firebase';
import { SweetAlertResult } from 'sweetalert2';
import swal from 'sweetalert2';
import { UserDataService } from '../user-data/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * User doc in firestore
   * @type {Observable<UserData>}
   * @memberof AuthService
   */
  public user: Observable<UserData>;

  /**
   * set loading to true when user is logging in to trigger loader overlay in `login.component.html`
   * @type {boolean}
   * @memberof AuthService
   */
  public loading: boolean;

  /**
   * set loading to true when user is registering in to trigger loader bar in regsiter.component.html
   * @type {boolean}
   * @memberof AuthService
   */
  public userCreationLoading: boolean;

  /**
   * set to true when changing password then to false if either ended successfully or with failure
   * @type {boolean}
   * @memberof AuthService
   */
  public changedPassword: boolean;

  /**
   * City saved as display name in firebase authentication system
   * @type {string}
   * @memberof AuthService
   */
  public userCity: string;

  public admin: boolean;

  public shop: boolean;

  public service: boolean;

  constructor(private _afAuth: AngularFireAuth, private _afs: AngularFirestore, private _router: Router, public dialog: MatDialog,
  private _userDataService: UserDataService, private _snackBar: MatSnackBar) {
    this.loading = true;
    this.user = this._afAuth.authState.pipe(
      switchMap(userData => {
        if (userData) {
          setTimeout(() => {
            this.loading = false;
          }, 700);
          this.userCity = userData.displayName;
          if (userData.photoURL === 'admin') {
            this.admin = true;
          } else if (userData.photoURL === 'serwis') {
            this.service = true;
            this.shop = false;
            this.admin = false;
          } else if (userData.photoURL === 'sklep') {
            this.shop = true;
            this.service = false;
            this.admin = false;
          } else {
            this.shop = false;
            this.service = false;
            this.admin = false;
          }
          this._userDataService.getUserDoc(userData.displayName);
          return this._afs.doc<UserData>(`users/${userData.displayName}`).valueChanges();
        } else {
          this.loading = false;
          return of(null);
        }
      })
    );

    // setTimeout(() => {
    //   const ok = firebase.auth().currentUser;

    //   ok.updateProfile({
    //     displayName: 'Legnica',
    //     photoURL: null
    //   });
    //   console.log('ok');
    // }, 3000);
  }

  /**
   * signing in, since firebase tracks users activity sometimes logging in happens automatically to smooth UX
   * @param {string} email user's email
   * @param {string} password user's password
   * @memberof AuthService
   */
  public emailSignIn(email: string, password: string): void {
    this.loading = true;
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        setTimeout(() => {
          this.loading = false;
        }, 700);
      })
      .catch(err => {
        this._handelError(err);
        this.loading = false;
      });
  }

  /**
   * Signing out of firebase and automatically setting user to null
   * @memberof AuthService
   */
  public signOut(): void {
    this._afAuth.auth.signOut();
  }

  public changePassword(password: string): void {
    const user = firebase.auth().currentUser;
    user.updatePassword(password)
      .then(() => {
        this._snackBar.open('Hasło zostało zmienione ✔');
        setTimeout(() => {
          this._snackBar.dismiss();
        }, 2000);
      })
      .catch(err => {
        this._handelError(err);
      });
  }

  /**
   * Returns error with swal
   * @private
   * @param {*} err incoming error
   * @returns {Promise<SweetAlertResult>} swal modal popup
   * @memberof AuthService
   */
  private _handelError(err: any): Promise<SweetAlertResult> {
   return swal({
      type: 'error',
      title: err.code,
      text: err.message
    });
  }
}
