import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DevicesService } from '../../services/devices-service/devices.service';
import { DataService } from '../../services/data-service/data.service';
import { PagesService } from '../../services/pages-service/pages.service';
import { DeviceComponent } from '../device/device.component';
import { DeviceData } from '../../models/device.model';
import { columnNames } from './devices.data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceSearchComponent } from '../device-search/device-search.component';


@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, OnDestroy {

  public loading: boolean;

  public initialLoading: boolean;

  /**
   * Data stream of devices from firestore collection provided by `data.service.ts`
   * @type {Observable<Array<DeviceData>>}
   * @memberof DevicesComponent
   */
  public dataSource$: Observable<Array<DeviceData>>;

  /**
 * Form control made to filter dataSource$
 * @type {FormControl}
 * @memberof CustomersComponent
 */
  public filterFC: FormControl = new FormControl('', [Validators.required]);

  /**
   * array of column names for mat-table , array was created inside `devices.data.ts` file
   * @type {Array<string>}
   * @memberof DevicesComponent
   */
  public columns: Array<string> = columnNames;

  constructor(private _matDialog: MatDialog, public devicesService: DevicesService, private _dataService: DataService,
    private _pagesService: PagesService) { }

  /**
   * Method that filters `dataSource$` based on filterFC Form Control
   * @memberof DevicesComponent
   */
  public applyFilter(): void {
    // this.dataSource$ = this.dataSource$.pipe(
    //   map(data => {
    //     return data.filter((value: DeviceData) => value.deviceCatalogNumber.toLowerCase().includes(this.filterFC.value.toLowerCase()) ||
    //       value.deviceType.toLowerCase().includes(this.filterFC.value.toLowerCase()));
    //   })
    // );
    this.devicesService.deviceDialogShortVersion = false;
    this.devicesService.deviceSearchString = this.filterFC.value;
    this._matDialog.open(DeviceSearchComponent, {
      maxHeight: 700,
      maxWidth: 500
    });
  }

  /**
   * deletes device from firestore collection by invoking method from `devices.service.ts`
   * @param {string} deviceID
   * @memberof DevicesComponent
   */
  public deleteDevice(deviceID: string): void {
    this.devicesService.deleteDevice(deviceID);
  }

  /**
   * method that open dialog with `device.component.html`
   * @param {boolean} newDevice true if adding new device, false when selecting device that exists in database
   * @param {DeviceData} [device] OPTIONAL data about existing device that are passed to `devices.service.ts`
   * where are stored in seperate varibale
   * @memberof DevicesComponent
   */
  public openDialog(newDevice: boolean, device?: DeviceData): void {
    if (newDevice === false) {
      this.devicesService.selectDevice(device);
    } else {
      this.devicesService.deselectDevice();
    }
    this._matDialog.open(DeviceComponent, {
      maxHeight: 700
    });
  }

  ngOnInit(): void {
    // this.initialLoading = true;
    // this.dataSource$ = this._dataService.devices$.pipe(
    //   map(data => {
    //     this.initialLoading = false;
    //     return data;
    //   })
    // );
    this._pagesService.selectedDevice = true;
  }

  ngOnDestroy(): void {
    this._pagesService.selectedDevice = false;
  }

}
