import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { OrderData } from '../../models/order.model';
import { CustomerData } from '../../models/customer.model';
import { DeviceData } from '../../models/device.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  customerData$: Observable<CustomerData>;
  deviceData$: Observable<DeviceData>;
  orderData: OrderData;
  customerCopy: boolean;
  orderCity: any;
  readyToPrint: boolean;

  constructor(private _afs: AngularFirestore) {
    this.orderCity = {
      street: 'ul.Poznańska 4',
      cityInfo: '64-100 Leszno',
      phone: 'tel. 601 863 642'
    };
  }

  public selectToPrint(orderData: OrderData, customerCopy: boolean) {
    this.readyToPrint = false;
    this.orderData = orderData;
    switch (orderData.orderCity) {
      case 'Wrocław':
        this.orderCity = {
          street: 'ul. Krakowska 127',
          cityInfo: '50-428 Wrocław',
          phone: 'tel. 71/340-02-15'
        };
        break;
      case 'Legnica':
        this.orderCity = {
          street: 'ul. Wrocławska 101',
          cityInfo: '59-220 Legnica',
          phone: 'tel. 76/ 722-47-47'
        };
        break;
      case 'Głogów':
        this.orderCity = {
          street: 'ul. Piastowska 5',
          cityInfo: '67-200 Głogów',
          phone: 'tel. 76/ 835-91-09'
        };
        break;
      case 'Jelenia_góra':
        this.orderCity = {
          street: 'ul. Wincentego Pola 8',
          cityInfo: '58-500 Jelenia Góra',
          phone: '75/ 751-80-10'
        };
        break;
      default:
        this.orderCity = {
          street: 'ul.Poznańska 4',
          cityInfo: '64-100 Leszno',
          phone: 'tel. 601 863 643'
        };
        break;
    }
    this.customerCopy = customerCopy;
    this.customerData$ = this._afs.collection('companies').doc<CustomerData>(orderData.customer.customerID).valueChanges().pipe(
      map(data => {
        this.readyToPrint = true;
        return data;
      })
    );
    // this.deviceData$ = this._afs.collection('devices').doc<DeviceData>(orderData.device.deviceID).valueChanges();
  }
}
