import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { OrderData } from '../../models/order.model';
import { map } from 'rxjs/operators';
import { columnNames } from '../orders/orders.data';
import { triggerColumn } from '../../animations/triggerColumn.animation';
import { OrdersService } from '../../services/orders-service/orders.service';
import { OrderComponent } from '../order/order.component';
import { PrintService } from '../../services/print-service/print.service';
import { PrintComponent } from '../print/print.component';
import { AuthService } from 'src/app/services/auth-service/auth.service';


@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
  animations: [triggerColumn]
})
export class ArchiveComponent implements OnInit {

  private _ordersCollection: AngularFirestoreCollection<OrderData>;
  public orders$: Observable<Array<OrderData>>;
  public loading: boolean;
  public columns: Array<string> = columnNames;
  public expandedElement: OrderData;
  public orderStatus: FormControl = new FormControl('', [Validators.required]);
  public month: FormControl = new FormControl(new Date().getMonth() + 1, [Validators.required]);
  public year: FormControl = new FormControl(new Date().getFullYear(), [Validators.required]);
  public years: Array<number> = [];

  constructor(private _afs: AngularFirestore, private _matDialog: MatDialog, private _printService: PrintService,
     public ordersService: OrdersService, public authService: AuthService) { }

  public editStatus(orderID: string) {
    this.ordersService.editStatus(orderID, this.orderStatus.value);
  }

  public openDialog(orderID: string) {
    this._matDialog.open(OrderComponent, {
      minWidth: 1100,
      maxHeight: 705
    });
    this.ordersService.selectOrder(orderID);
  }

  public printOrder(data: OrderData, customerCopy: boolean) {
    this._printService.selectToPrint(data, customerCopy);
    this._matDialog.open(PrintComponent, {
      maxHeight: 700,
      maxWidth: 500,
      panelClass: 'custom-modal-dialog'
    });
  }

  public setOrderValue(order: string): void {
    this.orderStatus.setValue(order);
  }

  public changeMonth() {
    this.loading = true;
    const month = parseInt(this.month.value, 0);
    const year = parseInt(this.year.value, 0);
    this._ordersCollection = this._afs.collection<OrderData>('orders', ref => {
      return ref.where('orderMonth', '==', month).where('orderYear', '==', year);
    });
    this.orders$ = this._ordersCollection.valueChanges().pipe(
      map(data => {
        this.loading = false;
        return data;
      })
    );
  }

  public changeYear() {
    this.loading = true;
    const month = parseInt(this.month.value, 0);
    const year = parseInt(this.year.value, 0);
    this._ordersCollection = this._afs.collection<OrderData>('orders', ref => {
      return ref.where('orderMonth', '==', month).where('orderYear', '==', year);
    });
    this.orders$ = this._ordersCollection.valueChanges().pipe(
      map(data => {
        this.loading = false;
        return data;
      })
    );
  }

  ngOnInit() {
    const month: any = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    for (let i = 2018; i <= year; i++) {
      this.years.push(i);
    }
    this._ordersCollection = this._afs.collection<OrderData>('orders', ref => {
      return ref.where('orderMonth', '==', month).where('orderYear', '==', year).orderBy('sortNumber', 'desc');
    });
    this.orders$ = this._ordersCollection.valueChanges().pipe(
      map(data => {
        this.loading = false;
        return data;
      })
    );
  }

}
