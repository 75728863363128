export const lesznoEmployees: Array<string> = ['Adam Golec', 'Piotr Kajoch', 'Albert Kamiński', 'Piotr Bąkowski', 'Marcin Śląski', 'Cezary Kołopieńczyk'];

export const wroclawEmployees: Array<string> = ['Katarzyna Eksztajn', 'Wojciech Golec'];

export const glogowEmployees: Array<string> = ['Jadwiga Miechowicz'];

export const jeleniaGoraEmployees: Array<string> = ['Barbara Kaczoch'];

export const legnicaEmployees: Array<string> = ['Bronisław Wójcik'];

