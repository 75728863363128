import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data-service/data.service';
import { map } from 'rxjs/operators';
import { OrderData } from '../../models/order.model';
import { AngularFirestore } from 'angularfire2/firestore';
import { UserData } from '../../models/user.model';
import { lesznoEmployees, wroclawEmployees, legnicaEmployees, glogowEmployees, jeleniaGoraEmployees } from './employees';
import * as _ from 'lodash';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})

export class StatisticsComponent implements OnInit {

  public city: string;
  public month: number;
  public year: number;
  public years: Array<number> = [];
  public lineChartOptions: any = {
    responsive: true
  };
  public lineChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(255, 235, 59, 0.5)',
    },
    {
      backgroundColor: 'rgba(123, 130, 255, 0.6)',
    },
    {
      backgroundColor: 'rgba(142, 204, 27, 0.7)',
    },
    {
      backgroundColor: 'rgba(129, 56, 143, 0.8)',
    },
    {
      backgroundColor: 'rgba(27, 83, 204, 0.9)'
    }
  ];
  public lineChartLegend: boolean;
  public lineChartType: string;
  public cityMembers: Array<string>;
  public cityMembersData$: Observable<any>;
  public receivedSum: number;
  public servicedSum: number;
  public cities: Array<string> = ['Leszno', 'Wrocław', 'Głogów', 'Jelenia Góra', 'Legnica'];
  public citiesColors: Array<any> = [
    {
      backgroundColor: [
        '#FFEB3B',
        '#7B82FF',
        '#8FCC1B',
        '#80388f',
        '#1B53CC'
      ],
    }
  ];
  public citiesData$: Observable<any>;
  public months: Array<string> = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień',
    'Październik', 'Listopad', 'Grudzień'];

  public citiesSum: Array<number> = [];
  public statuses: Array<string> = ['Przyjęto do naprawy', 'Czekamy na decyzje klienta', 'Czekamy na części', 'W trakcie naprawy',
    'Naprawiono', 'Klient zrezygnował', 'Naprawiono i wydano', 'Klient zrezygnował i odebrał'];
  public ordersStatuses$: Observable<Array<number>>;


  constructor(public dataService: DataService, private _afs: AngularFirestore) { }

  public switchCity(): void {
    switch (this.city) {
      case 'Leszno':
        this.cityMembers = _.sortBy(lesznoEmployees);
        break;
      case 'Wrocław':
        this.cityMembers = _.sortBy(wroclawEmployees);
        break;
      case 'Głogów':
        this.cityMembers = _.sortBy(glogowEmployees);
        break;
      case 'Jelenia_góra':
        this.cityMembers = _.sortBy(jeleniaGoraEmployees);
        break;
      case 'Legnica':
        this.cityMembers = _.sortBy(legnicaEmployees);
        break;
    }
    this._getMembersStats();
    this._getOrdersData();
  }

  public switchMonth(): void {
    this._getMembersStats();
    this._getOrdersData();
    this._getCitiesData();
  }

  public switchYear(): void {
    this._getMembersStats();
    this._getOrdersData();
    this._getCitiesData();
  }

  private _getMembersStats(): void {
    this.cityMembersData$ = this._afs.collection<OrderData>('orders').valueChanges().pipe(
      map(data => {
        const servicemanData = [];
        const receiverData = [];

        for (let i = 0; i < this.cityMembers.length; i++) {
          servicemanData[i] = 0;
          receiverData[i] = 0;
        }

        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < this.cityMembers.length; j++) {
            if (data[i].servicemanName === this.cityMembers[j] && data[i].orderMonth === this.month && data[i].orderYear === this.year) {
              servicemanData[j]++;
            }

            if (data[i].receiverName === this.cityMembers[j] && data[i].orderMonth === this.month && data[i].orderYear === this.year) {
              receiverData[j]++;
            }
          }
        }

        this.servicedSum = _.sum(servicemanData);
        this.receivedSum = _.sum(receiverData);

        return { servicemanData: servicemanData , receiverData: receiverData };
      })
    );
  }

  private _getOrdersData(): void {
    let month = this.month.toString();
    if (month.length === 1) {
      month = `0${month}`;
    }
    this.ordersStatuses$ = this._afs.collection<any>('orders').valueChanges().pipe(
      map(data => {
        const ordersStatuses: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0];
        for (let i = 0; i < data.length; i++) {
          if (data[i].orderCity === this.city && data[i].orderStatus === 1
            && data[i].orderDate.substring(3, 5) === month) {
            ordersStatuses[0]++;
          } else if (data[i].orderCity === this.city && data[i].orderStatus === 2
            && data[i].orderDate.substring(3, 5) === month) {
            ordersStatuses[1]++;
          } else if (data[i].orderCity === this.city && data[i].orderStatus === 3
            && data[i].orderDate.substring(3, 5) === month) {
            ordersStatuses[2]++;
          } else if (data[i].orderCity === this.city && data[i].orderStatus === 4
            && data[i].orderDate.substring(3, 5) === month) {
            ordersStatuses[3]++;
          } else if (data[i].orderCity === this.city && data[i].orderStatus === 5
            && data[i].orderDate.substring(3, 5) === month) {
            ordersStatuses[4]++;
          } else if (data[i].orderCity === this.city && data[i].orderStatus === 6
            && data[i].orderDate.substring(3, 5) === month) {
            ordersStatuses[5]++;
          } else if (data[i].orderCity === this.city && data[i].orderStatus === 7
            && data[i].orderDate.substring(3, 5) === month) {
            ordersStatuses[6]++;
          } else if (data[i].orderCity === this.city && data[i].orderStatus === 8
            && data[i].orderDate.substring(3, 5) === month) {
            ordersStatuses[7]++;
          }
        }
        return ordersStatuses;
      })
    );
  }

  private _getCitiesData(): void {
    let month = this.month.toString();
    if (month.length === 1) {
      month = `0${month}`;
    }
    this.citiesData$ = this._afs.collection<OrderData>('orders').valueChanges().pipe(
      map(data => {
        const lesznoOrders: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const wroclawOrders: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const glogowOrders: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const jeleniaOrders: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const legnicaOrders: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const citiesMonthOrders: Array<number> = [0, 0, 0, 0, 0];
        const citiesMonthlyRevenueData: Array<any> = [0, 0, 0, 0, 0];
        for (let i = 0; i < data.length; i++) {
          if (data[i].orderCity === 'Leszno' && data[i].orderDate.substring(6, 10) === this.year.toString()) {
            lesznoOrders[(parseInt(data[i].orderDate.substring(3, 5), 0) - 1)]++;
          } else if (data[i].orderCity === 'Wrocław' && data[i].orderDate.substring(6, 10) === this.year.toString()) {
            wroclawOrders[(parseInt(data[i].orderDate.substring(3, 5), 0) - 1)]++;
          } else if (data[i].orderCity === 'Głogów' && data[i].orderDate.substring(6, 10) === this.year.toString()) {
            glogowOrders[(parseInt(data[i].orderDate.substring(3, 5), 0) - 1)]++;
          } else if (data[i].orderCity === 'Jelenia_góra' && data[i].orderDate.substring(6, 10) === this.year.toString()) {
            jeleniaOrders[(parseInt(data[i].orderDate.substring(3, 5), 0) - 1)]++;
          } else if (data[i].orderCity === 'Legnica' && data[i].orderDate.substring(6, 10) === this.year.toString()) {
            legnicaOrders[(parseInt(data[i].orderDate.substring(3, 5), 0) - 1)]++;
          }

          if (data[i].orderDate.substring(3, 5) === month && data[i].orderDate.substring(6, 10) === this.year.toString() ) {
            switch (data[i].orderCity) {
              case 'Wrocław':
                citiesMonthOrders[1]++;
                citiesMonthlyRevenueData[1] += parseFloat(data[i].partsPrice);
                break;
              case 'Głogów':
                citiesMonthOrders[2]++;
                citiesMonthlyRevenueData[2] += parseFloat(data[i].partsPrice);
                break;
              case 'Jelenia_góra':
                citiesMonthOrders[3]++;
                citiesMonthlyRevenueData[3] += parseFloat(data[i].partsPrice);
                break;
              case 'Legnica':
                citiesMonthOrders[4]++;
                citiesMonthlyRevenueData[4] += parseFloat(data[i].partsPrice);
                break;
              default:
                citiesMonthOrders[0]++;
                citiesMonthlyRevenueData[0] += parseFloat(data[i].partsPrice);
                break;
            }
          }
        }
        this.citiesSum[0] = _.sum(lesznoOrders);
        this.citiesSum[1] = _.sum(wroclawOrders);
        this.citiesSum[2] = _.sum(glogowOrders);
        this.citiesSum[3] = _.sum(jeleniaOrders);
        this.citiesSum[4] = _.sum(legnicaOrders);

        for (let i = 0; i < 5; i++) {
          citiesMonthlyRevenueData[i] = citiesMonthlyRevenueData[i].toFixed(2);
        }

        return {
          yearData: [
            {
              data: lesznoOrders,
              label: 'Leszno'
            },
            {
              data: wroclawOrders,
              label: 'Wrocław'
            },
            {
              data: glogowOrders,
              label: 'Głogów'
            },
            {
              data: jeleniaOrders,
              label: 'Jelenia Góra'
            },
            {
              data: legnicaOrders,
              label: 'Legnica'
            },
          ],
          monthData: citiesMonthOrders,
          revenueData: citiesMonthlyRevenueData
        };
      })
    );
  }

  ngOnInit(): void {
    this.city = 'Leszno';
    this.month = new Date().getMonth() + 1;
    this.year = new Date().getFullYear();
    this.cityMembers = _.sortBy(lesznoEmployees);
    for (let i = 2019; i <= new Date().getFullYear(); i++) {
      this.years.push(i);
    }
    this.lineChartLegend = true;
    this.lineChartType = 'line';
    this._getMembersStats();
    this._getOrdersData();
    this._getCitiesData();
  }

}
