import { Component, OnInit } from '@angular/core';
import { PrintService } from '../../services/print-service/print.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {

  constructor(public printService: PrintService) { }

  public print() {
    window.print();
  }

  ngOnInit() { }
}
