import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CustomersService } from 'src/app/services/customers-service/customers.service';
import { CustomerSearchComponent } from '../customer-search/customer-search.component';

@Component({
  selector: 'app-customer-order-search',
  templateUrl: './customer-order-search.component.html',
  styleUrls: ['./customer-order-search.component.scss']
})
export class CustomerOrderSearchComponent implements OnInit {

  public searchInputsValues = {
    customerName: '',
    customerStreet: '',
    customerCity: '',
    customerPostalCode: '',
    customerEmail: '',
    customerPhoneNumber: '',
    customerNIP: ''
  };

  constructor(public customersService: CustomersService, private _matDialog: MatDialog) { }

  openSearchDialog(searchParameter: string) {
    let searchString;
    switch (searchParameter) {
      case 'customerName':
        searchString = this.searchInputsValues.customerName;
        break;
      case 'customerStreet':
        searchString = this.searchInputsValues.customerStreet;
        break;
      case 'customerCity':
        searchString = this.searchInputsValues.customerCity;
        break;
      case 'customerPostalCode':
        searchString = this.searchInputsValues.customerPostalCode;
        break;
      case 'customerEmail':
        searchString = this.searchInputsValues.customerEmail;
        break;
      case 'customerPhoneNumber':
        searchString = this.searchInputsValues.customerPhoneNumber;
        break;
      case 'customerNIP':
        searchString = this.searchInputsValues.customerNIP;
        break;
    }
    this.customersService.customerSearchString = searchString;
    this.customersService.customerSearchTerms = searchParameter;
    this._matDialog.closeAll();
    this._matDialog.open(CustomerSearchComponent, {
      maxHeight: 700,
      maxWidth: 500
    });
  }

  ngOnInit() {
  }

}
