import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { CustomersService } from "../customers-service/customers.service";
import { CustomerData } from "../../models/customer.model";
import { DeviceData } from "../../models/device.model";
import { MediaService } from "../media-service/media.service";

@Injectable({
  providedIn: "root",
})
export class PagesService {
  /**
   * Customer data of selected customer from `customers.service.ts`
   * @type {CustomerData}
   * @memberof PagesService
   */
  public customerData: CustomerData;

  /**
   * Device data of selected device from `device.service.ts`
   * @type {DeviceData}
   * @memberof PagesService
   */
  public deviceData: DeviceData;

  /**
   * Method that changes `[expanded]` value of material expander on both `payable-order.component.html`
   * and `warranty-order.component.html`
   * @type {boolean}
   * @memberof PagesService
   */
  public expanderExpanded: boolean;

  /**
   * When set to true sets values of device or customer forms (depends in which method is set to true)
   * and completes values of those forms with values from whether `devices.service.ts` or `customers.service.ts`
   * @type {boolean}
   * @memberof PagesService
   */
  public navigatedFromCustomer: boolean;

  /**
   * When set to true sets values of device or customer forms (depends in which method is set to true)
   * and completes values of those forms with values from whether `devices.service.ts` or `customers.service.ts`
   * @type {boolean}
   * @memberof PagesService
   */
  public navigatedFromDevice: boolean;

  /**
   * duplicate of `customer.component` to prevent circular dependency detected message when user is trying to comeback from
   * `order.component` to `customer.component`
   * @type {*}
   * @memberof PagesService
   */
  public customerComponent: any;

  /**
   * triggers arrow that is displayed (if this variable is set to true) on `order.component.html` and enables to back
   * from `order.component.html` to `customer.component.html`
   * @type {boolean}
   * @memberof PagesService
   */
  public showArrow: boolean;

  /**
   * If user is in `home.component.html` set to true which add class `tab-selected` to home div in mat-sidenav in `app.component.html`
   * @type {boolean}
   * @memberof PagesService
   */
  public selectedHome: boolean;

  /**
   * If user is in `orders.component.html`, `order-payable.component.html` or `order-warranty.component.html` set to true
   * which add class `tab-selected` to home div in mat-sidenav in `app.component.html`
   * @type {boolean}
   * @memberof PagesService
   */
  public selectedOrder: boolean;

  /**
   * If user is in `customers.component.html` set to true which add class `tab-selected` to home div in mat-sidenav in `app.component.html`
   * @type {boolean}
   * @memberof PagesService
   */
  public selectedCustomer: boolean;

  /**
   * If user is in `devices.component.html` set to true which add class `tab-selected` to home div in mat-sidenav in `app.component.html`
   * @type {boolean}
   * @memberof PagesService
   */
  public selectedDevice: boolean;

  /**
   * If user is in `archive.component.html` set to true which add class `tab-selected` to home div in mat-sidenav in `app.component.html`
   * @type {boolean}
   * @memberof PagesService
   */
  public selectedArchive: boolean;

  constructor(
    private _matDialog: MatDialog,
    private _router: Router,
    private _customersService: CustomersService,
    private _mediaService: MediaService
  ) {
    const appInfo = {
      Version: "1.2.1",
      "Build date": "06-02-2022",
    };
    console.table(appInfo);
  }

  /**
   * method that opens order specified in link param and then sets values of customer form group
   * @param {CustomerData} customerData data of customer from `customers.service.ts`
   * @param {string} link string that is later set in ulr bar and guides to one of two order components
   * @memberof PagesService
   */
  public selectCustomer(customerData: CustomerData, link: string): void {
    this.customerData = customerData;
    this._customersService.customerType = this.customerData.customerType;
    this.navigatedFromCustomer = true;
    this._matDialog.closeAll();
    this._router.navigateByUrl(link);
    setTimeout(() => {
      this.expanderExpanded = true;
    }, 50);
  }

  /**
   * method that opens order specified in link param and then sets values of device form group
   * @param {DeviceData} deviceData data of device from `devices.service.ts`
   * @param {string} link string that is later set in ulr bar and guides to one of two order components
   * @memberof PagesService
   */
  public selectDevice(deviceData: DeviceData, link: string): void {
    this.deviceData = deviceData;
    this.navigatedFromDevice = true;
    this._matDialog.closeAll();
    this._router.navigateByUrl(link);
    setTimeout(() => {
      this.expanderExpanded = true;
    }, 50);
  }

  /**
   * Method that opens `customer.component`, it opens dialog in 2 ways:
   *
   * - first one opens dialog only with only option to add customer, then dialog has smaller size (maxWitdth set to 400) and
   * doesn't display any informations about customer orders (it can't show those informations 'cause customer hasn't beed added yet)
   *
   * - second one opens with options to edit currently existed customer and displays customer's
   * order (if has any, if hasn't than it displays proper message) and by click on order it opens mat dialog with `order.component.html`
   * @param {*} [component] OPTIONAL customer.component
   * @param {string} [customerID] OPTIONAL id of customer from `customers.service.ts`
   * @param {CustomerData} [customer] OPTIONAL data of customer from `customer.service.ts`
   * @memberof PagesService
   */
  public openDialog(
    component?: any,
    customerID?: string,
    customer?: CustomerData
  ): void {
    if (component !== undefined) {
      this.customerComponent = component;
    }
    if (customerID === undefined && component !== undefined) {
      this._matDialog.open(this.customerComponent, {
        maxHeight: 700,
        minWidth: 400,
      });
    } else {
      this._matDialog.closeAll();
      if (customer !== undefined) {
        this._customersService.selectCustomer(customer);
      }
      this.showArrow = false;
      setTimeout(() => {
        this._matDialog.open(this.customerComponent, {
          maxHeight: 700,
          minWidth: 800,
        });
      }, 50);
    }
  }
}
