import { Component, OnInit } from '@angular/core';
import { AbstractControl, Validators, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth-service/auth.service';
import { UserDataService } from '../../services/user-data/user-data.service';
import { UserData } from '../../models/user.model';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  public membersPhoneNumbers: FormGroup;
  public password: FormGroup;

  /**
   * Boolean that toggles visibility of password in form
   * @type {boolean}
   * @memberof LoginComponent
   */
  public hide: boolean;

  constructor(private _formBuilder: FormBuilder, public userDataService: UserDataService, public authService: AuthService) { }

  get newPassword(): AbstractControl {
    return this.password.get('password');
  }

  get newPasswordMatch(): AbstractControl {
    return this.password.get('passwordMatch');
  }

  public comparePasswords(): boolean {
    if (this.newPassword.value === this.newPasswordMatch.value) {
      return true;
    }
    return false;
  }

  public changePassword(): void {
    this.authService.changePassword(this.newPassword.value);
  }

  public changePhoneNumbers(members: Array<any>): void {
    this.userDataService.changePhoneNumbers(this.membersPhoneNumbers.value, members);
  }

  private _selectEquipmentType(name: string): FormGroup {
    return this._formBuilder.group({
      'phoneNumber': [name, [Validators.required]]
    });
  }

  private _addSelectedEquipmentType(name: string): void {
    const device: FormArray = <FormArray>this.membersPhoneNumbers.controls['memberPhoneNumber'];
    const addEquipmentType: FormGroup = this._selectEquipmentType(name);
    device.push(addEquipmentType);
  }

  ngOnInit(): void {
    this.hide = true;
    this.authService.user.subscribe(data => {
      this.membersPhoneNumbers = this._formBuilder.group({
        'memberPhoneNumber': this._formBuilder.array([
          this._formBuilder.group({
            'phoneNumber': [data.users[0].phoneNumber, [Validators.required]]
          })
        ])
      });
      for (let i = 1; i < data.users.length; i++) {
        this._addSelectedEquipmentType(data.users[i].phoneNumber);
      }
    });
    this.password = this._formBuilder.group({
      'password': ['', [
        Validators.required,
        Validators.minLength(5)
      ]],
      'passwordMatch': ['', [
        Validators.required,
        Validators.minLength(5)
      ]]
    });
  }
}
