import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor() {
    console.log('What were you looking for here  ( ͡° ͜ʖ ͡°) ?');
    console.group('Group', ['Oh hi mark', 'Sup boy? ']);
   }

  ngOnInit() {
  }

}
