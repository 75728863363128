import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CustomersService } from '../../services/customers-service/customers.service';
import { OrdersService } from '../../services/orders-service/orders.service';
import { PagesService } from '../../services/pages-service/pages.service';
import { OrderComponent } from '../order/order.component';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  /**
   * Group form that contains all details about customer, values of this form group
   * are taken from `customers.service.ts`
   * @type {FormGroup}
   * @memberof CustomerComponent
   */
  public customer: FormGroup;

  constructor(private _formBuilder: FormBuilder, private _matDialog: MatDialog, public customersService: CustomersService,
    public ordersService: OrdersService, public pagesService: PagesService) { }

  // Set of getters to get values from form controls

  get customerName(): AbstractControl {
    return this.customer.get('customerName');
  }

  get customerStreet(): AbstractControl {
    return this.customer.get('customerStreet');
  }

  get customerPostalCode(): AbstractControl {
    return this.customer.get('customerPostalCode');
  }

  get customerCity(): AbstractControl {
    return this.customer.get('customerCity');
  }

  get customerPhoneNumber(): AbstractControl {
    return this.customer.get('customerPhoneNumber');
  }

  get customerEmail(): AbstractControl {
    return this.customer.get('customerEmail');
  }

  get customerNIP(): AbstractControl {
    return this.customer.get('customerNIP');
  }

  /**
   * adds customer to firestore collection by invoking `customersService.addCustomer(value)` method
   * as value it passes all values from `customer` form group
   * @memberof CustomerComponent
   */
  public addCustomer(): void {
    this.customersService.addCustomer(this.customer.value);
  }

  /**
   * edits customer in firestore by invoking `customersService.editCustomer(value)` method
   * as value it passes all values from `customer` form group
   * @memberof CustomerComponent
   */
  public editCustomer(): void {
    this.customersService.editCustomer(this.customer.value);
  }

  /**
   * Method that opens mat dialog with `order.component.html` template, also method invokes selectOrder method from
   * `orders.service.ts` with orderID value to select certain order
   * @param {string} orderID ID of selected on which is based later `order.component.ts`
   * @memberof CustomerComponent
   */
  public openDialog(orderID: string): void {
    this._matDialog.closeAll();
    this.pagesService.showArrow = true;
    this._matDialog.open(OrderComponent, {
      minWidth: 1100,
      maxHeight: 705
    });
    this.ordersService.selectOrder(orderID);
  }

  ngOnInit(): void {
    this.customer = this._formBuilder.group({
      'customerName': [this.customersService.selectedCustomer.customerName, [Validators.required]],
      'customerStreet': [this.customersService.selectedCustomer.customerStreet, [Validators.required]],
      'customerPostalCode': [this.customersService.selectedCustomer.customerPostalCode, [
        Validators.required,
        Validators.pattern('^[0-9]{2}-[0-9]{3}$')
      ]],
      'customerCity': [this.customersService.selectedCustomer.customerCity, [Validators.required]],
      'customerPhoneNumber': [this.customersService.selectedCustomer.customerPhoneNumber, [
        Validators.required,
        Validators.pattern('^[0-9]{3}-?[0-9]{3}-?[0-9]{3}$')
      ]],
      'customerEmail': [this.customersService.selectedCustomer.customerEmail],
      'customerNIP': [this.customersService.selectedCustomer.customerNIP]
    });
  }

}
